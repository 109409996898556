/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input, Popconfirm, Space, Typography } from 'antd';
import PageWrapper from '../../../../ui/PageWrapper';
import { useLocation } from 'react-router-dom';
import useAddAppointmentPage from './UseAddAppointmentPage';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';

import useStudentsTable from './useStudentsTable';

interface Props {
  loading: boolean;
  selectable?: boolean;
  selectedStudentId?: any;
  setSelectedStudentId?: any;
  selectedStudent?: any;
  setSelectedStudent?: any;
  data: StudentDtoResponse[];
}
interface TableColumns {
  id: number;
  userId: number;
  fullName: string;
  group?: string;
  courseGrade?: string;
}



const AddAppointmentPage = (props: any) => {
  const location = useLocation();
  let state: any = {};
  state = location.state;

  const {
    searchValue,
    setSearchValue,
    studentsList,
    studentsCount,
    loading,
    handleSearchStudents,

  } = useAddAppointmentPage();


  const StudentsTable = ({
    loading,
    data,
    selectable = false,
    selectedStudentId,
    setSelectedStudentId,
    selectedStudent,
    setSelectedStudent,
  }: Props) => {
    const navigate = useHistory();
    const { filteredData, rowSelection, confirm } = useStudentsTable(data, navigate, {
      selectedStudentId,
      setSelectedStudentId,
      selectedStudent,
      setSelectedStudent,
    });
    const columns: ColumnsType<TableColumns> = [
      {
        title: 'ФИО',
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        title: 'Группа',
        dataIndex: 'group',
        key: 'group',
      },
      {
        title: 'Курс',
        dataIndex: 'courseGrade',
        key: 'courseGrade',
      },
      {
        dataIndex: 'Action',
        key: 'firstTime',
        render: (_: any, record: any) => (
          <>
            {

              <Popconfirm
                placement="topLeft"
                title={'Confirm an appointment?'}
                onConfirm={() => confirm(record, state.date, state.time)}
                okText="Yes"
                cancelText="No"
              >
                <a>Add appointment</a>
              </Popconfirm>
            }
          </>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={filteredData}
        style={{ paddingTop: 20 }}
        loading={loading}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
      />
    )
  };

  return (
    <PageWrapper>
      <Typography.Title level={4}>Время: {state?.weekDay}({state?.date}) {state?.time}</Typography.Title>
      <Typography.Title level={3}>Поиск студентов</Typography.Title>

      <Space direction="vertical" size={20}>
        <Input.Search
          placeholder="Введите ФИО Студента"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) => handleSearchStudents(value)}
          enterButton
          style={{ width: 400 }}
        />

      </Space>
      {studentsCount > 0 && <Typography.Title level={5}>Найдено студентов: {studentsCount}</Typography.Title>}
      {studentsList.length > 0 && <StudentsTable loading={loading} data={studentsList} />}
    </PageWrapper>
  );
};

export default AddAppointmentPage;