import authAxios from '../common/authAxios';
import { NotificationDtoRequest } from '../interfaces/Notification/request/NotificationDtoRequest';
import { NotificationDtoRsponse } from '../interfaces/Notification/response/NotificationDtoResponse';

export class NotificationService {

    static createNotification(data: NotificationDtoRequest) {
        return authAxios.post<any>('/astanait-authorization-module/api/v1/notification', data);
    }
    static getNotification() {
        return authAxios.get<NotificationDtoRsponse[]>(
            `/astanait-authorization-module/api/v1/notification/principal/new`, {
            headers: {
                showNotification: false
            }
        }
        );
    }
    static getNotificationByUser(page: number) {
        return authAxios.get<{ data: NotificationDtoRsponse[]; total_pages: number }>(
            `/astanait-authorization-module/api/v1/notification/principal?page=${page}&pageSize=10`
        );
    }
    static updateNotificationStatus(notificationId: number) {
        return authAxios.put(`/astanait-authorization-module/api/v1/notification?id=${notificationId}`);
    }
    static getAllNotification(page: number) {
        return authAxios.get<{ data: NotificationDtoRsponse[]; total_pages: number }>(
            `/astanait-authorization-module/api/v1/notification?page=${page}&pageSize=10`
        );
    }
}