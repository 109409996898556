import styles from './style.module.css';
import { Typography, Table, Button, Pagination } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import useAcademicMobility from './useNotificationPage';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;


const AcademicMobilityPage = () => {
    const { notification, column, currentPage, totalPages, setCurrentPage } = useAcademicMobility();

    return (
        <PageWrapper>
            <section>
                <header className={styles.header}>
                    <Title level={3} className={styles.title}>
                        Notification List
                    </Title>
                    <Link to="/notification-create">
                        <Button type="primary" icon={<PlusOutlined />}>
                            Add new Notification
                        </Button>
                    </Link>

                </header>
                <Table
                    pagination={{
                        current: currentPage,
                        total: totalPages * 10,
                        pageSize: 10,
                        onChange: (page) => setCurrentPage(page),
                    }}
                    dataSource={notification.map((notification) => ({
                        key: notification.id,
                        student: notification.user.name + " " + notification.user.surname,
                        message: notification.message.text,
                        createdDate: notification.message.createdAt,
                        isRead: notification.isRead,
                        readAt: notification.readAt,
                        moodleMessageIsRead: notification.moodleMessageIsRead,
                        moodleMessageReadAt: notification.moodleMessageReadAt,

                    }))}
                    columns={column}
                />
            </section>
        </PageWrapper>
    )
}

export default AcademicMobilityPage;