import { Button, Input, InputRef, notification, Space, Tag } from 'antd';
import { useEffect, useState, useRef } from 'react';
import grantPermission from '../../../../helpers/grantPermission';
import { CertificateIssuanceRequestDtoResponse } from '../../../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import { CertificateIssuanceType } from '../../../../interfaces/deansOffice/CertificateIssuanceType';
import { CertificateIssuanceService } from '../../../../services/deansOffice/CertificateIssuanceService';
import { CertificateService } from '../../../../services/deansOffice/CertificateService';
import { TranscriptService } from '../../../../services/TranscriptService';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CertificateType } from '../../../../interfaces/deansOffice/CertificateType';
import { useForm } from 'antd/es/form/Form';

interface FilterRow {
  text: string;
  value: string;
}

const useCertificateList = () => {
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
  const [spinner, setSpinner] = useState(false);
  const [all, setAll] = useState<CertificateIssuanceRequestDtoResponse[]>([]);
  const [file, setFile] = useState<any | null>(null);
  const [modalView, setModalView] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectModalValue, setRejectModalValue] = useState<CertificateIssuanceRequestDtoResponse>();
  const [rejectModalList, setRejectModalList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState<CertificateIssuanceRequestDtoResponse>();
  const [edsPass, setEdsPass] = useState<string>();
  const inputRef = useRef() as React.Ref<InputRef>;
  const [certificateTypes, setCertificateTypes] = useState<CertificateType[]>([]);
  const [form] = useForm();

  useEffect(() => {
    CertificateIssuanceService.getCertificateTypes()
      .then(({ data }) => setCertificateTypes(data.filter(c => c.id !== 1 && c.id !== 2)));
  }, []);

  const getColumnSearchProps = (dataIndex: string, inputRef: React.Ref<InputRef>) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      return `${record.student.nameEn} ${record.student.surnameEn}`.toLowerCase().includes(value.toLowerCase());
    },
  });

  const handleRejectModalShow = (record: CertificateIssuanceRequestDtoResponse) => {
    setRejectModalValue(record);
    setShowModal(true);
  };

  const columns = [
    {
      title: 'Full Name',
      key: 'fullName',
      render: (record: CertificateIssuanceRequestDtoResponse) =>
        concatValues(record.student.surnameEn, record.student.nameEn),
      ...getColumnSearchProps('fullName', inputRef),
    },
    {
      title: 'Group',
      dataIndex: ['student', 'group', 'title'],
      key: 'group',
      filters: filterRows,
      onFilter: (title: any, record: CertificateIssuanceRequestDtoResponse) =>
        record.student.group.title.indexOf(title) === 0,
    },
    {
      title: 'Issuance type',
      dataIndex: 'issuanceType',
      key: 'issuanceType',
      filters: [
        {
          text: 'Email',
          value: CertificateIssuanceType.EMAIL,
        },
        {
          text: 'Pickup',
          value: CertificateIssuanceType.PICKUP,
        },
      ],
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issuanceType.indexOf(value) === 0,
    },
    {
      title: 'Type of certificate',
      dataIndex: ['type', 'name'],
      key: 'type',
      filters: certificateTypes.map((types) => {
        return {
          text: types.name,
          value: types.id,
        };
      }),
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.type.id === value,
    },
    {
      title: 'Requested date',
      render: (record: CertificateIssuanceRequestDtoResponse) => getFullDate(record.requestedDate.toString()),
    },
    {
      title: 'Status',
      filters: [
        {
          text: 'Received',
          value: 'RECEIVED',
        },
        {
          text: 'Requested',
          value: 'REQUESTED',
        },
        {
          text: 'Denied',
          value: 'DENIED',
        },
      ],
      render: (record: CertificateIssuanceRequestDtoResponse) => {
        switch (record.issued) {
          case 'REQUESTED':
            return <Tag color="blue">Requested</Tag>;
          case 'RECEIVED':
            return <Tag color="green">Received</Tag>;
          case 'DENIED':
            return <Tag color="red">Denied</Tag>;
        }
      },
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issued.indexOf(value) === 0,
    },
    {
      title: 'Make certificate',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Button type="primary" onClick={() => makeCertificate(record)}>
          Download
        </Button>
      ),
    },
    {
      title: 'Reasons',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Button
          onClick={() => handleRejectModalShow(record)}
          disabled={record.reasonStudent === null || record.reasonStudent === ''}
        >
          Show reason
        </Button>
      ),
    },
    {
      title: 'Send to email?',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Space>
          <Button
            onClick={() => openModal(record)}
            disabled={record.issued === 'RECEIVED' || record.issued === 'DENIED'}
            style={{ background: 'green', border: '1px solid green' }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              if (record?.type.id) {
                setModalValue(record);
                setRejectModal(true);
              }
            }}
            disabled={record.issued === 'RECEIVED' || record.issued === 'DENIED'}
            danger
          >
            No
          </Button>
        </Space>
      ),
    },
  ];

  const multipleColumns = [
    {
      title: 'Full Name',
      key: 'fullName',
      render: (record: CertificateIssuanceRequestDtoResponse) =>
        concatValues(record.student.surnameEn, record.student.nameEn),
      ...getColumnSearchProps('fullName', inputRef),
    },
    {
      title: 'Group',
      dataIndex: ['student', 'group', 'title'],
      key: 'group',
      filters: filterRows,
      onFilter: (title: any, record: CertificateIssuanceRequestDtoResponse) =>
        record.student.group.title.indexOf(title) === 0,
    },
    {
      title: 'Issuance type',
      dataIndex: 'issuanceType',
      key: 'issuanceType',
      filters: [
        {
          text: 'Email',
          value: CertificateIssuanceType.EMAIL,
        },
        {
          text: 'Pickup',
          value: CertificateIssuanceType.PICKUP,
        },
      ],
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issuanceType.indexOf(value) === 0,
    },
    {
      title: 'Type of certificate',
      dataIndex: ['type', 'name'],
      key: 'type',
      filters: certificateTypes.map((types) => {
        return {
          text: types.name,
          value: types.id,
        };
      }),
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.type.id === value,
    },
    {
      title: 'Requested date',
      render: (record: CertificateIssuanceRequestDtoResponse) => getFullDate(record.requestedDate.toString()),
    },
    {
      title: 'Status',
      filters: [
        {
          text: 'Received',
          value: 'RECEIVED',
        },
        {
          text: 'Requested',
          value: 'REQUESTED',
        },
        {
          text: 'Denied',
          value: 'DENIED',
        },
      ],
      render: (record: CertificateIssuanceRequestDtoResponse) => {
        switch (record.issued) {
          case 'REQUESTED':
            return <Tag color="blue">Requested</Tag>;
          case 'RECEIVED':
            return <Tag color="green">Received</Tag>;
          case 'DENIED':
            return <Tag color="red">Denied</Tag>;
        }
      },
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issued.indexOf(value) === 0,
    },
    {
      title: 'Reason',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Button
          onClick={() => handleRejectModalShow(record)}
          disabled={record.reasonStudent === null || record.reasonStudent === ''}
        >
          Show reason
        </Button>
      ),
    },
  ];

  const getFullDate = (val: string): string => {
    const dateAndTime = val.split('T');
    const date = dateAndTime[0].split('-').reverse().join('-');
    const time = dateAndTime[1].split('.')[0];
    return date + ' ' + time;
  };

  const openModal = (record?: CertificateIssuanceRequestDtoResponse) => {
    if (record) {
      if (record?.type.id) {
        setModalValue(record);
        setModalView(true);
      } else {
        sendToEmailWithOutEDS(record);
      }
    } else {
      setModalView(true);
    }
  };

  const sendToEmailWithOutEDS = (record: CertificateIssuanceRequestDtoResponse) => {
    setSpinner(true);
    let formData = new FormData();
    formData.append('issuance-request-id', record.id.toString());
    CertificateIssuanceService.notifyAboutReadyEmail(formData)
      .then((res) => {
        notification.success({
          message: 'Success',
          description: 'Your successfully sent certificate!',
          duration: 1.5,
        });
        fetchData();
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const handleCancel = () => {
    setEdsPass(undefined);
    setFile(undefined);
    setModalView(false);
  };

  const onIssued = (record: CertificateIssuanceRequestDtoResponse) => {
    setSpinner(true);
    CertificateIssuanceService.setIssued(record.id)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'Your successfully issued certificate!',
          duration: 1.5,
        });
        fetchData();
      })
      .finally(() => setSpinner(false));
  };

  const handleImage = (e: any) => {
    setFile(e);
  };

  const onFinish = () => {
    if (file !== null) {
      let formData = new FormData();
      formData.append('eds-password', edsPass!);
      formData.append('issuance-request-id', modalValue?.id?.toString()!);
      formData.append('eds', file);
      CertificateIssuanceService.notifyAboutReadyEmail(formData)
        .then((res) => {
          notification.success({
            message: 'Success',
            description: 'Your successfully sent certificate!',
            duration: 1.5,
          });
          fetchData();
        })
        .finally(() => {
          setSpinner(false);
          modalOnFinish();
        });
    }
  };

  const onFinishReject = (request: CertificateIssuanceRequestDtoResponse) => {
    let formData = new FormData();
    formData.append('issuance-request-id', modalValue?.id?.toString()!);
    formData.append('reasonDeans', request.reasonDeans);
    CertificateIssuanceService.rejectDeans(formData)
      .then((res) => {
        notification.success({
          message: 'Rejected successfully',
          description: 'You successfully rejected request!',
          duration: 1.5,
        });
        fetchData();
      })
      .finally(() => {
        setSpinner(false);
        modalOnFinish();
      });
  };

  const handleSubmitFile = () => { };

  const handleRejectList = (ids: number[], reason: string) => {
    if (ids) {
      setSpinner(true);
      let formData = new FormData();
      formData.append('reasonDeans', reason);
      CertificateIssuanceService.rejectDeansList(formData, ids)
        .then((res) => {
          notification.success({
            message: 'Rejected successfully',
            description: 'You successfully rejected request!',
            duration: 1.5,
          });
          fetchData();
        })
        .finally(() => {
          setSpinner(false);
          modalOnFinish();
        });
    }
  };

  const handleSubmitFileList = (ids: number[]) => {
    if (file !== null && ids) {
      setSpinner(true);
      let formData = new FormData();
      formData.append('eds-password', edsPass!);
      formData.append('eds', file);
      CertificateIssuanceService.notifyEmailList(formData, ids)
        .then((res) => {
          notification.success({
            message: 'Success',
            description: 'Your successfully sent certificate!',
            duration: 1.5,
          });
          fetchData();
        })
        .finally(() => {
          setSpinner(false);
          modalOnFinish();
        });
    }
  };

  const modalOnFinish = () => {
    // setEdsPass(undefined);
    // setFile(undefined);
    setModalView(false);
    setRejectModal(false);
  };

  const notifyAboutReadiness = (record: CertificateIssuanceRequestDtoResponse) => {
    setSpinner(true);
    CertificateIssuanceService.notifyAboutReadyPickup(record.id)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'You successfully notified!',
          duration: 1.5,
        });
        fetchData();
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const makeCertificate = (record: CertificateIssuanceRequestDtoResponse) => {
    setSpinner(true);
    CertificateService.makeCertificate(record)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully sent certificate issuance ' + record.issuanceType.toString(),
          duration: 1.5,
        });
      })
      .finally(() => {
        setSpinner(false);
        fetchData();
      });
  };

  const makeCertificateMultiple = (ids: number[]) => {
    ids.forEach((id) => {
      CertificateIssuanceService.getIssuanceById(id)
        .then((res) => {
          makeCertificate(res.data);
        });
    });
  };

  const concatValues = (val1: string | undefined, val2: string | undefined) => {
    if (val1 === void 0 || val2 === void 0) {
      return '';
    }
    return val1 + ' ' + val2;
  };
  const fetchData = () => {
    setSpinner(true);
    CertificateIssuanceService.getAll()
      .then((data) => {
        setAll(data.data);
      })
      .finally(() => setSpinner(false));
  };

  useEffect(() => {
    if (grantPermission('OFFICE')) {
      TranscriptService.getAllStudentsGroup().then(({ data }) => {
        let res = data.map((item) => {
          return {
            text: item.title,
            // @ts-ignore
            value: item.title,
          };
        });
        setFilterRows(res);
      });
      fetchData();
    }
  }, []);

  const onUploadChange = (e: any) => {
    e.file.status = 'done';
  };

  return {
    filterRows,
    setFilterRows,
    spinner,
    setSpinner,
    all,
    setAll,
    file,
    setFile,
    modalView,
    rejectModal,
    setRejectModal,
    rejectModalList,
    setRejectModalList,
    setModalView,
    modalValue,
    setModalValue,
    edsPass,
    setEdsPass,
    columns,
    multipleColumns,
    getFullDate,
    openModal,
    sendToEmailWithOutEDS,
    handleCancel,
    onIssued,
    handleImage,
    handleSubmitFileList,
    handleSubmitFile,
    handleRejectList,
    onFinish,
    onFinishReject,
    modalOnFinish,
    notifyAboutReadiness,
    makeCertificate,
    makeCertificateMultiple,
    concatValues,
    fetchData,
    onUploadChange,
    showModal,
    setShowModal,
    form,
    rejectModalValue,
  };
};

export default useCertificateList;
