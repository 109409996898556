import { Button, Form, Input, Select, Space } from 'antd';
import useStudentDetails from './useStudentDetails';
import { useEffect, useState } from 'react';
import { StudentDtoResponse } from '../../../interfaces/Student/StudentDtoResponse';


interface StudentSearchFormProps {
    onData: (studentsList: StudentDtoResponse[], loading: boolean, selectedTrimester: number) => void;
    isAttendance?: boolean;
    isDetails?: boolean;
}

const StudentSearchForm: React.FC<StudentSearchFormProps> = ({ onData, isAttendance = false, isDetails = false }) => {
    const {
        searchValue,
        setSearchValue,
        loading,
        handleSearchStudents,
        studentsList,
    } = useStudentDetails({});

    const trimesterStaticForAttendance = [
        { id: 202, name: 'Trimester 1' },
        { id: 205, name: 'Trimester 2' },
        { id: 206, name: 'Trimester 3' }
    ];
    const trimesterStaticForGrades = [
        { id: 1, name: 'Trimester 1' },
        { id: 2, name: 'Trimester 2' },
        { id: 3, name: 'Trimester 3' }
    ];

    const [selectedTrimester, setSelectedTrimester] = useState<number>(0);

    const handleTrimesterChange = (value: number) => {
        setSelectedTrimester(value);
    };

    useEffect(() => {
        onData(studentsList, loading, selectedTrimester);
    }, [studentsList, loading, onData, selectedTrimester]);

    return (
        <Space direction="vertical" size={20} style={{ marginBottom: 20 }}>

            <><Select style={{ width: 400 }} placeholder="Выберите триместр текущего учебного года" onChange={handleTrimesterChange} onSelect={(value) => value} allowClear>
                {isAttendance ? (trimesterStaticForAttendance.map((trimester) => (
                    <Select.Option key={trimester.id} value={trimester.id}>
                        {`${trimester.name}`}
                    </Select.Option>
                ))) :
                    (trimesterStaticForGrades.map((trimester) => (
                        <Select.Option key={trimester.id} value={trimester.id}>
                            {`${trimester.name}`}
                        </Select.Option>
                    )))}
            </Select>
                <Input.Search
                    placeholder="Введите ФИО Студента"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={(value) => handleSearchStudents(value)}
                    enterButton
                    style={{ width: 400 }} /></>
        </Space >
    )
}
export default StudentSearchForm;