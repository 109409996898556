import { Alert, Button, Checkbox, DatePicker, Form, Input, Select, Space, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { errorNotification } from '../../../../helpers/errorNotification';
import { successNotification } from '../../../../helpers/successNotification';
import { TeacherDtoRequest } from '../../../../interfaces/Teacher/TeacherDtoRequest';
import { HRService } from '../../../../services/HRService';
import PageWrapper from '../../../../ui/PageWrapper';
import useHR from '../useHR';
import useDictionary from '../../../../hooks/useDictionary';
import { useState } from 'react';
import { TeacherAdditionalInfoDtoRequest } from '../../../../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';

const TeacherCreationPage = () => {
  const { dictionary } = useDictionary({
    fetchGender: true,
    fetchNationailty: true,
    fetchAcademicStatus: true,
    fetchScientificDegree: true,
    fetchKpiType: true,
    fetchCountries: true,
  });
  const { Option } = Select;
  const { data } = useHR();
  const [isChecked, setIsChecked] = useState(false);
  const additionalInfoDtoRequest: TeacherAdditionalInfoDtoRequest[] = [];


  const onFormFinish = (teacher: TeacherDtoRequest, additionalInfo: TeacherAdditionalInfoDtoRequest[]) => {

    teacher.additionalInfo = additionalInfo.filter(value => value);

    HRService.createTeacher(teacher)
      .then(() => successNotification('Teacher created successfully!'))
      .catch((error) => {
        if (error.response?.status === 404) {
          errorNotification(error.response.data.message);
        } else {
          errorNotification('Unable to create teacher', error.response?.status);
        }
      });
  };

  const onFormFinishFailed = (errorInfo: any) => {
    errorNotification('Заполните все поля!');
  };

  return (
    <PageWrapper>
      <Typography.Title level={3}>Create new teacher</Typography.Title>
      <Alert message="If a teacher doesn't have account, login and password of teacher will send to your mail" type='warning'></Alert>
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        size="large"
        form={data.form}
        style={{ width: '800px', margin: '0 auto' }}
        onFinish={v => onFormFinish(v, additionalInfoDtoRequest)}
        onFinishFailed={onFormFinishFailed}
      >
        <FormItem
          label="Корпоративная почта"
          name="username"
          rules={[{ required: true, message: 'Введите корпоративную почту' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите корпоративную почту" />
        </FormItem>
        <FormItem
          label="Фамилия"
          name="surnameKz"
          rules={[{ required: true, message: 'Введите фамилию' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите фамилию" />
        </FormItem>
        <FormItem
          label="Имя"
          name="nameKz"
          rules={[{ required: true, message: 'Введите имя' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите имя" />
        </FormItem>
        <FormItem
          label="Отчество"
          name="patronymicKz"
          rules={[{ required: false, message: 'Введите отчество' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите отчество" />
        </FormItem>
        <FormItem
          label="Фамилия(транслитом)"
          name="surnameEn"
          rules={[{ required: true, message: 'Введите фамилию(транслитом)' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите фамилию(транслитом)" />
        </FormItem>
        <FormItem
          label="Имя(транслитом)"
          name="nameEn"
          rules={[{ required: true, message: 'Введите имя(транслитом)' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите имя(транслитом)" />
        </FormItem>
        <FormItem
          label="ИИН"
          name="iin"
          rules={[{ required: true, message: 'Введите ИИН' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input placeholder="Введите ИИН" maxLength={12} style={{ width: '50%' }} />
        </FormItem>
        <FormItem
          label="Пол"
          name="sexId"
          rules={[{ required: true, message: 'Выберите пол' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите пол" allowClear>
            {dictionary.genderList.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.value}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Национальность"
          name="nationality"
          rules={[{ required: true, message: 'Выберите национальность' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите национальность" allowClear>
            {dictionary.nationalities?.map((option: any) => (
              <Option key={option.id} value={option.id}>
                {`${option.titleKz}/${option.titleRu}/${option.titleEn}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Тип"
          name="typeId"
          rules={[{ required: true, message: 'Выберите тип' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите тип" allowClear>
            {data.teacherTypeList?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.name}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Должность"
          name="positionId"
          rules={[{ required: true, message: 'Выберите должность' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите должность" allowClear>
            {data.positionsList?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.name}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="KPI позиция"
          name="kpiPosition"
          rules={[{ required: true, message: 'Выберите должность' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите должность" allowClear>
            {dictionary.positions?.map((option) => (
              <Option key={option} value={option}>
                {`${option}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Ученая степень"
          name="scientificDegreeId"
          rules={[{ required: true, message: 'Выберите ученую степень' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите ученую степень" allowClear>
            {dictionary.scientificDegrees?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Академический статус"
          name="academicStatusId"
          rules={[{ required: true, message: 'Выберите академический статус' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите академический статус" allowClear>
            {dictionary.academicStatuses?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Статус"
          name="statusId"
          rules={[{ required: true, message: 'Выберите статус' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите статус" allowClear>
            {data.teacherStatusList?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.name}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Департамент"
          name="departmentId"
          rules={[{ required: true, message: 'Выберите департамент' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Выберите департамент" allowClear>
            {data.deps?.map((option) => (
              <Option key={option.id} value={option.id}>
                {`${option.titleKz}/${option.titleRu}/${option.titleEn}`}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          label="Зарубежный учитель"
          valuePropName="checked"
          name="ftutor"
          initialValue={false}
          style={{ marginBottom: '10px' }}
        >
          <Checkbox onChange={e => setIsChecked(e.target.checked)} />
        </FormItem>

        {isChecked &&
          // Object.keys(data.typeTitles).map((key, index) => {
          //   return (
          //     <FormItem label={data.typeTitles[key]} key={index}>
          //       <Input
          //         placeholder={data.typeTitles[key]}
          //         onChange={e => additionalInfoDtoRequest[index] = { type: key, value: e.target.value }}
          //       />
          //     </FormItem>
          //   );
          // })
          (
            <>
              <FormItem label={"Наименование организации зарубежного ученого"} style={{ marginBottom: '10px' }}>
                <Input
                  placeholder={"Напишите наименование организации зарубежного ученого"}
                  onChange={e => additionalInfoDtoRequest[0] = { type: "F_UNIVERSITY", value: e.target.value }}
                />
              </FormItem>

              <FormItem label={"Страна расположения вуза зарубежного ученого"} style={{ marginBottom: '10px' }}>
                <Select placeholder={"Выберите страну расположения вуза"}
                  onSelect={(e) => additionalInfoDtoRequest[1] = { type: "F_UNIVERSITY_COUNTRY", value: e }}>
                  {dictionary.countries?.map((data) => (
                    <Option key={data.id} value={data.id}>
                      {`${data.nameRu}`}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem label={"Специальность"} style={{ marginBottom: '10px' }}>
                <Input
                  placeholder={"Напишите специальность"}
                  onChange={e => additionalInfoDtoRequest[2] = { type: "SPECIALITY", value: e.target.value }}
                />
              </FormItem>

              <Space style={{ marginBottom: '10px' }}>
                <FormItem label={"Дата начала пребывания"}>
                  <DatePicker format="YYYY-MM-DD" placeholder='Выберите дату' onChange={(value, dateString) => additionalInfoDtoRequest[3] = { type: "F_START_DATE", value: dateString }} />
                </FormItem>
                <FormItem label={"Дата окончания пребывания"}>
                  <DatePicker format="YYYY-MM-DD" placeholder='Выберите дату' onChange={(value, dateString) => additionalInfoDtoRequest[4] = { type: "F_END_DATE", value: dateString }} />
                </FormItem>
              </Space>
            </>
          )
        }

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </PageWrapper>
  );
};

export default TeacherCreationPage;
