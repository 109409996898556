import React, { useState } from 'react';
import { Table, Button, Modal, Form, Popconfirm, Input, Select, Space, DatePicker, Checkbox, Upload, UploadProps, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Field } from '../../../interfaces/Field';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import { FileService } from '../../../services/file/FileService';

interface Record {
    [key: string]: any;
}

interface UniversalTableProps {
    columns: ColumnsType<Record>;
    dataSource: Record[];
    addRecord: (record: any) => void;
    editRecord: (record: any) => void;
    deleteRecord: (record: any) => void;
    editModalLogic?: (record: any) => void;
    createModalLogic?: (record: any) => void;
    hideEdit?: boolean;
    form?: FormInstance;
    customForm?: React.ReactNode;
    fields?: Field[];
    isPublic?: boolean;
}

const UniversalTable: React.FC<UniversalTableProps> = ({
    columns,
    dataSource,
    addRecord,
    editRecord,
    deleteRecord,
    createModalLogic,
    editModalLogic,
    hideEdit = false,
    fields,
    form: parentForm,
    customForm,
    isPublic
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState<Record | null>(null);
    const [form] = Form.useForm();
    const finalForm = parentForm || form;


    const uploadProps: UploadProps = {
        beforeUpload: (file) => {
            return false;
        },
        onChange: (e) => {
            form.setFieldsValue({ [fields?.find(field => field.type === "file")?.name as string]: e.fileList[0].originFileObj as RcFile });
        },
    }

    const showModal = (record: Record | null) => {
        if (record) {
            setCurrentRecord(record);
            finalForm.setFieldsValue(record);

            if (editModalLogic) {
                editModalLogic(record);
            }
        }
        else {
            setCurrentRecord(null);
            finalForm.resetFields();
            if (createModalLogic) {
                createModalLogic(record);
            }
        }

        setIsModalVisible(true);
    };

    const handleOk = () => {
        finalForm
            .validateFields()
            .then((values) => {
                if (currentRecord) {
                    editRecord({ ...currentRecord, ...values });
                } else {
                    addRecord(values);
                }
                finalForm.resetFields();
                setIsModalVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            })
            .finally(() => {
                setCurrentRecord(null)
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDelete = (record: Record) => {
        deleteRecord(record);
    };


    const handleDownload = (record: Record) => {
        FileService.getFileById(record.fileId, fields?.find(field => field.type === "file")?.name as string);
    };

    const actionColumn = {
        title: 'Action',
        key: 'action',
        render: (_: string, record: Record) => (
            <Space>
                <Button icon={<EditOutlined />} hidden={hideEdit} onClick={() => showModal(record)}>Edit</Button>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                    <Button danger icon={<DeleteOutlined />} >Delete</Button>
                </Popconfirm>
                {fields?.find(field => field.type === "file") && <Button icon={<DownloadOutlined />} onClick={() => handleDownload(record)}>Download</Button>}
            </Space>
        ),
    };

    return (
        <Space direction='vertical' style={{ width: "100%" }}>
            {isPublic ? <></> : <Button icon={<PlusOutlined />} type="primary" onClick={() => showModal(null)}>
                Add
            </Button>}
            <Table rowKey={(record, i = 0) => i.toString()} dataSource={dataSource} columns={isPublic ? columns : [...columns, actionColumn]} />
            <Modal open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {customForm ? customForm :
                    (<Form layout='vertical' form={form}>
                        {fields?.map((field) => {
                            switch (field.type) {
                                case 'input':
                                    return <Form.Item name={field.name} rules={[{ required: true, }]} label={field.label}><Input placeholder={field.placeholder} /></Form.Item>;
                                case 'text':
                                    return <Form.Item name={field.name} rules={[{ required: true }]} label={field.label}><Input placeholder={field.placeholder} /></Form.Item>;
                                case 'textArea':
                                    return <Form.Item name={field.name} rules={[{ required: true }]} label={field.label}><Input.TextArea placeholder={field.placeholder} /></Form.Item>;
                                case 'year':
                                    return (
                                        <Form.Item
                                            name={field.name}
                                            label={field.label}
                                            rules={[{ required: true }]}
                                            getValueFromEvent={(onChange) => moment(onChange).format('YYYY')}
                                            getValueProps={(i) => ({ value: i ? moment(new Date(Date.UTC(i, 0))) : null })}
                                        >
                                            <DatePicker
                                                placeholder='Enter year'
                                                style={{ width: "100%" }}
                                                picker={"year"}
                                            />
                                        </Form.Item>
                                    );
                                case 'date':
                                    return (
                                        <Form.Item
                                            name={field.name}
                                            label={field.label}
                                            rules={[{ required: true }]}
                                            getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                                            getValueProps={(i) => ({ value: i ? moment(i) : null })}
                                        >
                                            <DatePicker placeholder='Enter date' style={{ width: "100%" }} />
                                        </Form.Item>
                                    );
                                case 'checkbox':
                                    return (
                                        <Form.Item initialValue={false} rules={[{ required: true }]} valuePropName="checked" name={field.name} label={field.label}>
                                            <Checkbox>
                                            </Checkbox>
                                        </Form.Item>
                                    );
                                case 'select':
                                    return (
                                        <Form.Item name={field.name} rules={[{ required: true }]} label={field.label}>
                                            <Select placeholder={field.placeholder}>
                                                {field.options?.map((option) => (
                                                    <Select.Option value={option.value} placeholder={field.placeholder}>{option.label}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    );
                                case 'file':
                                    return (
                                        <Form.Item name={field.name} rules={[{ required: true }]} label={field.label} valuePropName="file">
                                            <Upload {...uploadProps} >
                                                <Button icon={<UploadOutlined />}>Upload file</Button>
                                            </Upload>
                                        </Form.Item>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </Form>)}
            </Modal>
        </Space>
    );
};

export default UniversalTable;