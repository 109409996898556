import authAxios from '../common/authAxios';
import { FinancingSourceDto } from '../interfaces/FinancingSourceDto';



export class FinancingSourceService {
    private static baseUrl = '/astanait-office-module/api/v1/academic-department/financing-source/'

    static getFinancingSourceById(id: number) {
        return authAxios.get<FinancingSourceDto>(
            `${this.baseUrl}get-by-id?id=${id}`
        );
    }

    static getAllFinancingSource() {
        return authAxios.get<FinancingSourceDto>(
            `${this.baseUrl}get-all`
        );
    }
}