/* eslint-disable array-callback-return */
import React, { Dispatch, SetStateAction } from 'react'
import { Avatar, Button, Card, Checkbox, Descriptions, Form, List, Modal, Select, Space, Steps, Typography } from 'antd';
import { ApplicationInfoDtoResponse } from '../../../interfaces/Applications/response/ApplicationInfoDtoResponse';
import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { FileService } from '../../../services/file/FileService';
import DataTypeComponent from './DataTypeComponent';
import { ApplicationInfoDtoRequest } from '../../../interfaces/Applications/request/ApplicationInfoDtoRequest';
import StyledGrid from './StyledGrid';
import { AbiturCatalogDtoResponse } from '../../../interfaces/AbiturCatalogDtoResponse';
import { StudentGroupsDtoResponse } from '../../../interfaces/Student/StudentGroupsDtoResponse';
import useApplications from './useApplications';
import grantPermission from '../../../helpers/grantPermission';
import { ApplicationDtoResponse } from '../../../interfaces/Applications/response/ApplicationDtoResponse';
import { EducationalProgramsService } from '../../../services/EducationalProgramsService';
import useModal from '../../../ui/Modal/useModal';
import { ThesisService } from '../../../services/ThesisService';
import { getFullDateWithTime } from '../../../common/getFullDate';
import { CommentDto, ParticipantDto } from '../../../interfaces/Thesis/ThesisDtos';

interface ApplicationInfoProps {
    application: ApplicationDtoResponse;
    applicationInfo: ApplicationInfoDtoResponse[];
    applicationId: number;
    isAccepted?: boolean;
    isEdit: boolean;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    handleUpdate: (applicationInfoDtoRequestList: ApplicationInfoDtoRequest[], id: number, fileId: number) => Promise<void>
}

const spaceItemStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
}

const handleThesisStatus = (status?: boolean, finishedDate?: string) => {
    switch (status) {
        case true:
            return `Подписан, ${finishedDate && getFullDateWithTime(finishedDate)}`;
        case false:
            return "Ожидает исправления";
        default:
            return "На рассмотрении";
    }
}


const ApplicationInfo = ({ application, applicationInfo, applicationId, isAccepted, isEdit, setIsEdit, handleUpdate }: ApplicationInfoProps) => {
    const [form] = Form.useForm();
    const { methods } = useApplications({ id: applicationId });
    const [participants, setParticipants] = React.useState<ParticipantDto[]>([]);
    const [comments, setComments] = React.useState<CommentDto[]>([]);

    const [selectData, setSelectData] = React.useState<Record<string, AbiturCatalogDtoResponse[]>>({});
    let oldFileId: number = 0;

    React.useEffect(() => {
        if (applicationInfo) {
            applicationInfo.map((record) => {
                if (record.applicationInfoTypes.dataType === "object") {
                    form.setFieldsValue({
                        [record.applicationInfoTypes.id]: record.value
                    })
                }
                form.setFieldsValue({ [record.applicationInfoTypes.id]: record.value })
                if (record.applicationInfoTypes.dataType === "file") {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    oldFileId = Number(record.value);
                    if (oldFileId) {
                        FileService.getFileBlobById(Number(record.value), record.applicationInfoTypes.name)
                            .then((response) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(response.data);
                                reader.onload = (e) => {
                                    const base64String = (reader.result as string).split(',')[1];
                                    form.setFieldsValue({ [record.applicationInfoTypes.id]: base64String })
                                };
                            });
                    }
                }
                if (record.applicationInfoTypes.dataType === "assign") {
                    if (record.applicationInfoTypes.prefix === "student_group") {
                        const info = applicationInfo.find(info => info.applicationInfoTypes.prefix === "education_program")
                        const educationalProgramId = info ? (info.applicationInfoTypes.dataType === "hidden" ? info.value : JSON.parse(info.value).id) : "";

                        if (educationalProgramId && grantPermission('STUDENT_DEPARTMENT', 'addRole')) {
                            EducationalProgramsService.getGroupsByEducationalProgram(educationalProgramId)
                                .then(({ data }) => {
                                    const newData = data.map((item: StudentGroupsDtoResponse) => ({
                                        id: item.id,
                                        name: item.title
                                    }));

                                    setSelectData(prevData => ({ ...prevData, [record.applicationInfoTypes.id]: newData }))
                                });
                        }

                    }
                }
                if (record.applicationInfoTypes.dataType === "hidden" && record.applicationInfoTypes.prefix === "thesis_doc_id" && applicationInfo) {
                    applicationInfo[0] && ThesisService.getDocumentParticipants(applicationInfo[0].value.toString()).then(({ data }) => {
                        setParticipants(data);
                    });
                    ThesisService.getDocumentComments(applicationInfo[0].value.toString())
                        .then(({ data }) => {
                            setComments(data);
                        })
                }
            })
        }
    }, [applicationInfo])




    return isEdit ? (
        <Modal title="Additional application information" destroyOnClose open={isEdit} onOk={form.submit} onCancel={() => setIsEdit(false)}>
            <Form form={form} layout='vertical'
                onFinish={(values: any) => {
                    handleUpdate(applicationInfo.map((record) => (
                        {
                            applicationInfoId: record.id,
                            applicationInfoTypesId: record.applicationInfoTypes.id,
                            value: values[record.applicationInfoTypes.id],
                        }
                    )),
                        applicationId,
                        oldFileId)
                        .finally(() => {
                            setIsEdit(false);
                        })
                }}>
                {applicationInfo.map((type, i) => (
                    <DataTypeComponent key={i} type={type.applicationInfoTypes} form={form} langId={application.lang} />
                ))}
            </Form>
        </Modal>
    ) : (
        <Card title="Additional application information">
            {applicationInfo?.map((record) => {
                switch (record.applicationInfoTypes.dataType) {
                    case "file":
                        return (
                            <StyledGrid key={record.id}>
                                <Space style={spaceItemStyle}>
                                    <b>{`${record.applicationInfoTypes.name}/${record.applicationInfoTypes.nameEn}/${record.applicationInfoTypes.nameRu}:`}</b>
                                    <Button icon={<DownloadOutlined />} onClick={() => {
                                        FileService.getFileById(Number(record.value), record.applicationInfoTypes.name);
                                    }}>
                                        Скачать
                                    </Button>
                                </Space>
                            </StyledGrid>
                        );
                    case "bool":
                        return (
                            <StyledGrid key={record.id}>
                                <Space style={spaceItemStyle}>
                                    <b>{`${record.applicationInfoTypes.name}/${record.applicationInfoTypes.nameEn}/${record.applicationInfoTypes.nameRu}`}</b>
                                    <Checkbox checked={record.value === "true"} />
                                </Space>
                            </StyledGrid>
                        );
                    case "object":
                        return (
                            <StyledGrid key={record.id}>
                                <Space style={spaceItemStyle}>
                                    <b>{`${record.applicationInfoTypes.name}/${record.applicationInfoTypes.nameEn}/${record.applicationInfoTypes.nameRu}`}</b>
                                    {record.applicationInfoTypes.applicationType.id === 12 ? record.value : JSON.parse(record.value).name}
                                </Space>
                            </StyledGrid>
                        );
                    case "date":
                        return (
                            <StyledGrid key={record.id}>
                                <Space style={spaceItemStyle}>
                                    <b>{`${record.applicationInfoTypes.name}/${record.applicationInfoTypes.nameEn}/${record.applicationInfoTypes.nameRu}`}</b>
                                    {record.value ? new Date(record.value).toLocaleDateString() : ""}
                                </Space>
                            </StyledGrid>
                        );
                    case "assign":
                        return grantPermission('STUDENT_DEPARTMENT', 'addRole') && (
                            <StyledGrid key={record.id}>
                                <b>{`${record.applicationInfoTypes.name}/${record.applicationInfoTypes.nameEn}/${record.applicationInfoTypes.nameRu}: `}</b>
                                <Select style={{ width: '50%' }}
                                    defaultValue={record.value ? JSON.parse(record.value).id : undefined}
                                    disabled={isAccepted && application.order !== null}
                                    onSelect={(value) => {
                                        const selectedOption = selectData[record.applicationInfoTypes.id]?.find((data: any) => data.id === value);
                                        methods.handleUpdateInfo(
                                            [
                                                {
                                                    applicationInfoId: record.id,
                                                    applicationInfoTypesId: record.applicationInfoTypes.id,
                                                    value: selectedOption ? JSON.stringify({ id: selectedOption.id, name: selectedOption.name }) : '',
                                                }
                                            ],
                                            applicationId,
                                        )
                                    }}>
                                    {selectData[record.applicationInfoTypes.id]?.map((data: any, i: number) => (
                                        <Select.Option key={i} value={data.id}>
                                            {data.name || data}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </StyledGrid>
                        )
                    case "hidden":
                        return record.applicationInfoTypes.prefix === "thesis_doc_id" && (
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={participants}
                                    rowKey={(record) => record.email.toString()}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={index}>
                                            <List.Item.Meta
                                                avatar={<Avatar icon={<UserOutlined />} />}
                                                title={`${item.name} (${item.email})`}
                                                description={handleThesisStatus(item.accepted, item.finishedDate)}
                                            />
                                        </List.Item>
                                    )}
                                />

                                {comments.length > 0 && <div style={{ width: "100%" }}>
                                    <Typography.Title level={4}>Comments</Typography.Title>
                                    <List
                                        itemLayout="vertical"
                                        dataSource={comments.sort((a, b) => new Date(a.createTs).getTime() - new Date(b.createTs).getTime())}
                                        rowKey={(record) => record.id.toString()}
                                        renderItem={(item, index) => (
                                            <List.Item
                                                key={item.id}
                                                extra={getFullDateWithTime(item.createTs)}
                                            >
                                                <List.Item.Meta
                                                    title={item.senderName}
                                                    description={<a href={`mailto:${item.email}`}>{item.email}</a>}
                                                />
                                                {item.comment}
                                            </List.Item>
                                        )}
                                    />
                                </div>}
                            </>
                        );
                    default:
                        break;
                }
            })}
        </Card>
    );

}
export default ApplicationInfo