import authAxios from '../common/authAxios';
import { downloadFile } from '../helpers/downloadFile';
import { OrderCategoryDtoResponse } from '../interfaces/Order/OrderCategoryDtoResponse';
import { OrderDtoResponse } from '../interfaces/Order/OrderDtoResponse';
import { OrderStudentDtoResponse } from '../interfaces/Order/OrderStudentDtoResponse';
import { OrderTypeDtoResponse } from '../interfaces/Order/OrderTypeDtoResponse';
import { StudentDtoResponse } from '../interfaces/Student/StudentDtoResponse';

interface CurrentContingentProps {
  academicDegreeId: number;
  date: string | null;
}

export class OrdersService {
  static getAllOrderTypes() {
    return authAxios.get<OrderTypeDtoResponse[]>(`/astanait-contingent-module/api/v1/order/type`);
  }

  static getOrderCategories(orderTypeId: number) {
    return authAxios.get<OrderCategoryDtoResponse[]>(`/astanait-contingent-module/api/v1/order/type/${orderTypeId}/categories`);
  }

  static getById(id: number) {
    return authAxios.get<OrderStudentDtoResponse>(`/astanait-contingent-module/api/v1/order/get-by-id?id=${id}`);
  }
  static getByOrderId(id: number) {
    return authAxios.get<OrderDtoResponse>(`/astanait-contingent-module/api/v1/order/get-by-order-id?id=${id}`);
  }

  static getAcademicMobilityOrderByStudentIdAndType(studentId: number) {
    return authAxios.get<OrderDtoResponse[]>(`/astanait-contingent-module/api/v1/order/get-by-student-id-and-type?studentId=${studentId}&type=${8}`);
  }


  static getStudentsByOrder(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<{ list: StudentDtoResponse[]; total_number: number }>(
      `/astanait-contingent-module/api/v1/order/get-students-suitable-for-the-type-of-order`,
      { params }
    );
  }

  static downloadCurrentContingentExcelFile(props: CurrentContingentProps) {
    let fileName;
    switch (props.academicDegreeId) {
      case 1:
        fileName = 'Контингент_Бакалавриат';
        break;
      case 2:
        fileName = 'Контингент_Магистратура';
        break;
      default:
        fileName = 'Контингент';
        break;
    }

    if (props.date) {
      return downloadFile(
        `/astanait-contingent-module/api/v1/contingent/current-contingent?academicDegreeId=${props.academicDegreeId}&date=${props.date}`,
        'GET',
        'blob',
        fileName,
        'xlsx'
      );
    }
    else {
      return downloadFile(
        `/astanait-contingent-module/api/v1/contingent/current-contingent?academicDegreeId=${props.academicDegreeId}`,
        'GET',
        'blob',
        fileName,
        'xlsx'
      );
    }
  }

  static studentListExcel(params: any) {
    return downloadFile(
      `/astanait-contingent-module/api/v1/order/list-of-students`,
      'GET',
      'blob',
      'Список студентов',
      'xlsx',
      params
    );
  }

  static downloadTemplate(params: any, userIds?: number[]) {
    return downloadFile(
      `/astanait-contingent-module/api/v1/order/word?students=${userIds}`,
      'GET',
      'blob',
      'Шаблон',
      'docx',
      params
    );
  }

  static downloadOrder(orderId: number, name: string) {
    return downloadFile(
      `/astanait-contingent-module/api/v1/order/download?orderId=${orderId}`,
      'GET',
      'blob',
      name.toString(),
      'pdf'
    );
  }

  static getOrdersByFilter(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<OrderDtoResponse[]>(`/astanait-contingent-module/api/v1/order/get-by-filter`, { params });
  }

  static createOrder(formData: FormData) {
    return authAxios.post<number>('/astanait-contingent-module/api/v1/order/create', formData);
  }

  static updateOrder(formData: FormData, orderId: number) {
    return authAxios.put(`/astanait-contingent-module/api/v1/order/update?orderId=${orderId}`, formData);
  }

  static uploadOrder(formData: FormData) {
    return authAxios.post<number>(`/astanait-contingent-module/api/v1/order/upload`, formData);
  }

  static updateOrderFile(formData: FormData, orderId: number) {
    return authAxios.post<number>(`/astanait-contingent-module/api/v1/order/update-file?orderId=${orderId}`, formData);
  }

  static getOrderListByStudent(studentId?: number) {
    return authAxios.get<OrderDtoResponse[]>(
      `/astanait-contingent-module/api/v1/order/get-by-student-id?studentId=${studentId}`
    );
  }

  static getOrderListByStudentAndType(studentId?: number, orderType?: number) {
    return authAxios.get<OrderDtoResponse[]>(
      `/astanait-contingent-module/api/v1/order/get-by-student-id-and-type?studentId=${studentId}&type=${orderType}`
    );
  }
}
