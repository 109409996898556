import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import AuthService from "../services/authService";

interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

const AuthorizedRoute = ({
  Component,
  path,
  exact = false,
}: Props): JSX.Element => {
  const message = "You must be logged in to view the requested page";
  const rExp: RegExp = new RegExp("/certificate-web-page/[0-9]+");
  const rExpEn: RegExp = new RegExp("/certificate-web-page-en/[0-9]+");

  if (window.location.href.endsWith("/public-teachers-list")) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          AuthService.isLoggedIn() ? (
            <>
              <Redirect
                to={{ pathname: "/teachers", state: { message, requestedPath: path } }}
              />
              <Component {...props} />
            </>
          ) : (
            <Redirect
              to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
            />
          )
        }
      />
    )
  }
  else if (window.location.href.endsWith("/public-certificate-verification")) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          <Redirect
            to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
          />
        }
      />
    )
  }
  else if (window.location.href.endsWith("/public/register-number")) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          <Redirect
            to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
          />
        }
      />
    )
  }
  else if (rExp.test(window.location.href) === true) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          <Redirect
            to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
          />
        }
      />
    )
  }
  else if (rExpEn.test(window.location.href) === true) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          <Redirect
            to={{ pathname: props.location.pathname, state: { message, requestedPath: path } }}
          />
        }
      />
    )
  }
  else {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          AuthService.isLoggedIn() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { message, requestedPath: path } }}
            />
          )
        }
      />
    );
  }
};

export default AuthorizedRoute;