import { useEffect, useState } from 'react';
import { Table, Typography, Button, Modal, Upload, message, UploadProps, Tag } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import {
  UploadOutlined,
} from '@ant-design/icons';
import useProfilePage from '../ProfilePage/useProfilePage';
import { SummerRetakeApplicationService } from './SummerRetakeApplicationService';

interface RetakeDisciplinesItem {
  disciplineId: number;
  discipline: string;
  fullname: string;
  studentId: number;
  totalGrade: number;
  volumeCredits: number;
}

interface RetakeStatusItem {
  id: number;
  fileName: string;
  createdDate: Date;
  approved: number;
  approvedDate: string | null;
  studentId: number | null;
  fileId: number;
  studentName: string;
  disciplineName: string;
  studentSurname: string;
  studentPatronymic: string | null;
  volumeCredits: number;
  comment: string;
  iin: string;
  disciplineId: number;
  approvedOfficeRegistrar:number;
}

interface Props {
  userId?: number;
}

const SummerRetakeDisciplines = ({ userId }: Props) => {
  const [retakeDisciplinesData, setRetakeDisciplinesData] = useState<RetakeDisciplinesItem[]>([]);
  const { profileInfo } = useProfilePage(userId);
  const [fileId, setFileId] = useState<number>();
  const [retakeStatusData, setRetakeStatusData] = useState<RetakeStatusItem[]>([]);
  const [showRetakeStatus, setShowRetakeStatus] = useState(false);
  const [showRetakeDisciplines, setShowRetakeDisciplines] = useState(true);
  const { Text } = Typography;

  const fetchRetakeStatusData = () => {
    const studentId = profileInfo?.student.id;
    if (studentId) {
      SummerRetakeApplicationService.getRetakeStatusForStudent(studentId)
        .then((response) => {
            setRetakeStatusData(response.data);
        })
        .catch((error) => {
          console.error('Ошибка при получении статуса заявки:', error);
        });
    }
  };


  const toggleRetakeDisciplines = () => {
    setShowRetakeDisciplines(true);
    setShowRetakeStatus(false);
    fetchRetakeDisciplinesData();
    fetchRetakeStatusData();
  };

  const toggleRetakeStatus = () => {
    setShowRetakeDisciplines(false);
    setShowRetakeStatus(true);
    fetchRetakeDisciplinesData();
    fetchRetakeStatusData();
  };

  useEffect(() => {
    fetchRetakeDisciplinesData();
  }, [profileInfo]);

  useEffect(() => {
    fetchRetakeStatusData();
  }, [profileInfo]);

  const fetchRetakeDisciplinesData = () => {
    const studentId = profileInfo?.student.id;
    if (studentId) {
        //SummerRetakeApplicationService.getRetakeDisciplinesForStudent(101)
        SummerRetakeApplicationService.getRetakeDisciplinesForStudent(studentId)
        .then((response) => {
          setRetakeDisciplinesData(response.data);
        })
        .catch((error) => {
          console.error('Ошибка при получении данных:', error);
        });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDiscipline, setSelectedDiscipline] = useState<RetakeDisciplinesItem | null>(null);
  const [fileList, setFileList] = useState<any>();
  
  const [disabledDisciplines, setDisabledDisciplines] = useState<number[]>([]);

  const showModal = (discipline: RetakeDisciplinesItem) => {
    setDisabledDisciplines((prev) => [...prev, discipline.disciplineId]);
    setSelectedDiscipline(discipline);
    setIsModalVisible(true);
  };
  

  const handleOk = () => {
    setIsModalVisible(false);
    handleUpload();
  };

  const handleCancel = () => {
    setDisabledDisciplines((prev) => prev.filter(id => id !== selectedDiscipline?.disciplineId));
    setIsModalVisible(false);
  };
  
  

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('retakeFile', fileList);

    if (selectedDiscipline) {
      SummerRetakeApplicationService.uploadFileRetakeApplication(formData)
        .then((response) => {
          setFileId(response.data);
          SummerRetakeApplicationService.createRetakeApplication(response.data, 
            selectedDiscipline.disciplineId,
            selectedDiscipline.volumeCredits
            );
          //successNotification('Application accepted for consideration');
          setFileList(null);
          setTimeout(() => {
            fetchRetakeDisciplinesData();
            fetchRetakeStatusData();
          }, 3000);
        })
        .catch((error) => {
          message.error('Ошибка загрузки файла');
          console.error('Error:', error);
        });
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    onChange(e) {
      e.file.status = 'done';
    },
    customRequest(info) {
      setFileList(info.file);
    },
  };

//   const isButtonDisabledForDiscipline = (disciplineId: number) => {
//     return retakeStatusData.some(
//       (item) => item.disciplineId === disciplineId && (item.approvedOfficeRegistrar === 1 || item.approvedOfficeRegistrar === 3)
//     );
//   };

  const columns = [
    // {
    //   title: 'Fullname',
    //   dataIndex: 'fullname',
    //   key: 'fullname',
    // },
    
    {
        title: 'Discipline',
        dataIndex: 'discipline',
        key: 'discipline',
      },
      {
        title: 'Credits',
        dataIndex: 'volumeCredits',
        key: 'volumeCredits',
      },
    //   {
    //     title: 'studentId',
    //     dataIndex: 'studentId',
    //     key: 'studentId',
    //   },
    //   {
    //     title: 'disciplineId',
    //     dataIndex: 'disciplineId',
    //     key: 'disciplineId',
    //   },
    //   {
    //     title: 'totalGrade',
    //     dataIndex: 'totalGrade',
    //     key: 'totalGrade',
    //   },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, record: RetakeDisciplinesItem) => {
        const totalCredits = Number(totalSum) + Number(record.volumeCredits);
        const isCreditExceeded = totalCredits > 27;
        const isApplicationProcessing = isButtonDisabledForDiscipline(record);
        const isDisabled = isCreditExceeded || isApplicationProcessing || disabledDisciplines.includes(record.disciplineId);
  
        let buttonText = '';
        if (isCreditExceeded) {
          buttonText = 'Number of credits should not exceed 27';
        } else if (isApplicationProcessing) {
          buttonText = 'Wait for your application to be processed';
        } else {
          buttonText = 'Submit a Retake application';
        }
  
        return (
          <Button onClick={() => (isDisabled ? null : showModal(record))} disabled={isDisabled}>
            {buttonText}
          </Button>
        );
      },
    },
    
      
  ];

  const getStatusText = (status: number) => {
    switch (status) {
      case 1:
        return <Tag color="blue">In processing</Tag>;
      case 2:
        return <Tag color="red">Declined</Tag>;
      case 3:
        return <Tag color="green">Approved</Tag>;
      default:
        return <Tag color="gray">Unknown status</Tag>;
    }
  };

  const formatDateString = (dateString: string): string => {
    if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
      return 'Unknown';
    }
    const date = new Date(dateString);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    return formattedDate;
  };

  const statusColumns = [
    // {
    //   title: 'duid',
    //   dataIndex: 'disciplineId',
    //   key: 'disciplineId',
    // },
    {
      title: 'Discipline',
      dataIndex: 'discipline',
      key: 'discipline',
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'approved',
    //   key: 'approved',
    //   render: (status: number) => <span>{getStatusText(status)}</span>,
    // },
    {
      title: 'Credits',
      dataIndex: 'volumeCredits',
      key: 'volumeCredits',
    },
    {
      title: 'Status',
      dataIndex: 'approvedOfficeRegistrar',
      key: 'approved',
      render: (status: number) => <span>{getStatusText(status)}</span>,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Submission date',
      dataIndex: 'createdDate',
      render: (text: string) => <span>{formatDateString(text)}</span>,
    },
    {
      title: 'Approved date',
      dataIndex: 'approvedDate',
      render: (text: string) => <span>{formatDateString(text)}</span>,
    },
  ];


  const filteredRetakeStatusData = retakeStatusData.filter(item => item.approvedOfficeRegistrar === 1 || item.approvedOfficeRegistrar === 3);

  const totalVolumeCredits = filteredRetakeStatusData.reduce((accumulator, currentItem) => accumulator + currentItem.volumeCredits, 0);

  
  const volumeCreditsDigits = totalVolumeCredits.toString().split('').map(Number);

  
  const totalSum = volumeCreditsDigits.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const isButtonDisabledForDiscipline = (record: RetakeDisciplinesItem) => {
    const totalCredits = Number(totalSum) + Number(record.volumeCredits);
    console.log('Total Credits:', totalCredits); 
    return (
      //totalCredits > 27 || 
      retakeStatusData.some(
        (item) => item.disciplineId === record.disciplineId && (item.approvedOfficeRegistrar === 1 || item.approvedOfficeRegistrar === 3)
      )
    );
  };
  
  
  return (
    <PageWrapper>
      <Typography.Title level={1}>Summer Retake Application </Typography.Title>
      <Typography.Title level={4}>Dear 1st and 2nd year students!!!</Typography.Title>
      <p>
      From May 13 to May 31, 2024 (inclusive) is carried out registration for the retake of academic disciplines for students of the 1st and 2nd year according to the results of the 1st and 2nd trimester of the 2023-2024 academic year.
      </p>
      <p>
      To retake the disciplines are allowed students who received grades “unsatisfactory” corresponding to the mark “F” (0-49 points) in the disciplines. The total number of credits may <strong>not exceed 27 credits in one academic year.</strong> 
      </p>
      <p>
      The order of registration for Retake: <br />
      1. Registration for Retake takes place through a personal account in the IS “Digital University”.  <br />
      2. The learner attaches the payment receipt in PDF format (from the payment history) in the Retake Application tab in Digital University (DU). 
      </p>
      <p>
        * Only if all items are completed, the learner is admitted to Retake!  <br />
        * Registration for Retake based on the results of the 3rd trimester will take place later<br />
        * The deadline for the summer semester is July 01 to August 10, 2024. <br />

      </p>
      <p>
        <Typography.Title level={4}>
        <strong>Cost of 1 credit:</strong>
        </Typography.Title>
        • Bachelor's degree 1,2,3 courses - 20000 tg <br />
        • Master's Degree 1-2 courses - 28000 tg <br />
        • Doctoral Degree 1 course - 45000 tg <br />
        • For foreign nationals on scholarship program (Bologna process) -10597 tg
      </p>
      <Typography.Title level={4}>
          <strong>Total amount of credits: </strong>  {totalSum}
        </Typography.Title>
      <Button style={{ margin: '30px 30px 30px 0' }} onClick={toggleRetakeDisciplines}>
      Submitting an application
      </Button>
      <Button onClick={toggleRetakeStatus} style={{ margin: '0 30px 30px 0' }}>
      Show application status
      </Button>

      {showRetakeDisciplines && (
        <div>
          
            <div>
              <Table dataSource={retakeDisciplinesData} columns={columns} scroll={{ x: '500px' }} />
            </div>
        
        </div>
      )}

      {showRetakeStatus && <Table dataSource={retakeStatusData} columns={statusColumns} scroll={{ x: '500px' }} />}

      <Modal
        title="Submitting an Application"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Send
          </Button>,
        ]}
      >
        <Upload {...uploadProps} fileList={fileList ? [fileList] : []}>
          <Button icon={<UploadOutlined />}>Select a file</Button>
        </Upload>
      </Modal>
    </PageWrapper>
  );
};

export default SummerRetakeDisciplines;
