import authAxios from '../common/authAxios';
import { ForeignUniversitiesDtoRequest } from '../interfaces/ForeignUniversitiesDtoRequest';
import { ForeignUniversitiesDtoResponse } from '../interfaces/ForeignUniversitiesDtoResponse';

export class ForeignUniversitiesService {

    private static baseUrl = '/astanait-office-module/api/v1/academic-department/universities'

    static getForeignUniversitiesById(universityId: number) {
        return authAxios.get<ForeignUniversitiesDtoResponse>(
            `${this.baseUrl}/get-by-id?id=${universityId}`
        );
    }

    static getAllForeignUniversities() {
        return authAxios.get<ForeignUniversitiesDtoResponse[]>(
            `${this.baseUrl}/get-all`
        );
    }

    static createForeignUniversity(foreignUniversity: ForeignUniversitiesDtoRequest) {
        return authAxios.post(
            `${this.baseUrl}/create`,
            foreignUniversity
        );
    }

    static deleteForeignUniversity(universityId: number) {
        return authAxios.delete<ForeignUniversitiesDtoResponse>(
            `${this.baseUrl}/delete?id=${universityId}`
        );
    }

    static updateForeignUniversity(universityId: number, foreignUniversity: ForeignUniversitiesDtoRequest) {
        return authAxios.put(
            `${this.baseUrl}/update?id=${universityId}`,
            foreignUniversity
        );
    }

}