import axios from 'axios';
import authAxios from '../common/authAxios';
import { DisciplineDtoResponse } from '../interfaces/DisciplineDtoResponse';
import { StudentGroupsDtoResponse } from '../interfaces/Student/StudentGroupsDtoResponse';
import { Constants } from '../common/constants';
import { TeacherCertificateDto } from '../interfaces/Teacher/TeacherCertificateDto';
import { TeacherEducationInformationResponceDto } from '../interfaces/Teacher/TeacherEducationInformationResponceDto';
import { TeacherEducationInformationRequestDto } from '../interfaces/Teacher/TeacherEducationInformationDtoRequest';
import { TeacherPositionDtoResponce } from '../interfaces/Teacher/TeacherPositionDtoResponce';
import { TeacherTypeDtoResponse } from '../interfaces/Teacher/TeacherTypeDtoResponse';
import { TeacherStatusDtoResponse } from '../interfaces/Teacher/TeacherStatusDtoResponse';
import { AcademicStreamWithGroupsDtoResponse } from '../interfaces/Teacher/AcademicStreamWithGroupsDtoResponse';
import { TeacherScholarInformationDtoRequest } from '../interfaces/Teacher/TeacherScholarInformationDtoRequest';
import { TeacherScholarInformationDtoResponse } from '../interfaces/Teacher/TeacherScholarInformationDtoResponse';
import { TeacherWorkExpDto } from '../interfaces/Teacher/TeacherWorkExpDto';
import { TeacherDevelopmentsDtoResponse } from '../interfaces/Teacher/TeacherDevelopmentsDtoResponse';
import { TeacherDevelopmentsDtoRequest } from '../interfaces/Teacher/TeacherDevelopmentDtoRequest';
import { AcademicDegreeDtoResponse } from '../interfaces/AcademicDegreeDtoResponse';
import { TeacherDegreeDtoResponse } from '../interfaces/Teacher/TeacherDegreeDtoResponse';
import { TeacherEducationalDegree } from '../interfaces/Teacher/TeacherEducationalDegree';
import { TeacherDegreeDtoRequest } from '../interfaces/Teacher/TeacherDegreeDtoRequest';
import { TeacherLegalDocsDtoResponce } from '../interfaces/Teacher/TeacherLegalDocsDtoResponce';
import { TeacherPatentTypeDtoResponse } from '../interfaces/Teacher/TeacherPatentTypeDtoResponse';
import { CacheService } from './CacheService';
import { TeacherScientificDegreesDto } from '../interfaces/Teacher/TeacherScientificDegreesDto';
import { TeacherAdditionalInfoDtoResponse } from '../interfaces/Teacher/TeacherAdditionalInfoDtoResponse';
import { TeacherDtoResponse } from '../interfaces/Teacher/TeacherDtoResponse';
import { TeacherArticleDtoResponse } from '../interfaces/Teacher/TeacherArticleDtoResponse';
import { TeacherArticleDtoRequest } from '../interfaces/Teacher/TeacherArticleDtoRequest';
import { TeacherScientificProjectsDtoResponse } from '../interfaces/Teacher/TeacherScientificProjectsDtoResponse';
import { TeacherCertificateDtoResponse } from '../interfaces/Teacher/TeacherCertificateDtoResponse';
import { TeacherLegalDocsDtoRequest } from '../interfaces/Teacher/TeacherLegalDocsDtoRequest';
import { TeacherAdditionalInfoTypesDto } from '../interfaces/Teacher/TeacherAdditionalInfoTypesDto';
import { CatalogDtoResponse } from '../interfaces/CatalogDtoResponse';
import { TeacherAdditionalInfoDtoRequest } from '../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';
import { TeacherOrderTypeDtoResponse } from '../interfaces/Teacher/Order/TeacherOrderTypeDtoResponse';
import { TeacherOrderCategoryDtoResponse } from '../interfaces/Teacher/Order/TeacherOrderCategoryDtoResponse';
import { TeacherOrderDtoRequest } from '../interfaces/Teacher/Order/TeacherOrderDtoRequest';
import { TeacherOrderDtoResponse } from '../interfaces/Teacher/Order/TeacherOrderDtoResponse';

export class TeacherService {
  static baseURL = Constants.API_BASE_URL;

  static getTeacherArticles() {
    return authAxios.get<TeacherArticleDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/article`);
  }

  static createOrUpdateTeacherArticle(data: TeacherArticleDtoRequest) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/article/create-or-update`, data);
  }

  static deleteTeacherArticle(articleId: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/article/delete/${articleId}`);
  }

  static getTeacherScientificProjects() {
    return authAxios.get<TeacherScientificProjectsDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/scientific-project`);
  }

  static createOrUpdateTeacherScientificProject(data: TeacherScientificProjectsDtoResponse) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/scientific-project/create-or-update`, data);
  }

  static deleteTeacherScientificProjects(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/scientific-project/delete/${id}`);
  }

  static getScholarInformationList() {
    return authAxios.get<TeacherScholarInformationDtoResponse[]>(
      `/astanait-teacher-module/api/v1/teacher/scholar-information`
    );
  }

  static createOrUpdateScholarInformation(scholarInformation: TeacherScholarInformationDtoRequest) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/scholar-information/create-or-update`, scholarInformation);
  }

  static deleteTeacherScholarInformation(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/scholar-information/delete/${id}`);
  }

  static deleteTeacherCertificate(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/certificate/delete/${id}`);
  }

  static updateTeacherCertificate(id: number, teacherCerificateDto: TeacherCertificateDto) {
    return authAxios.put(
      `/astanait-teacher-module/api/v1/teacher/certificate/update/${id}`,
      teacherCerificateDto.fileId
    );
  }

  static getTeacherEducationInformation(id: number) {
    return authAxios.get<TeacherEducationInformationResponceDto>(
      `/astanait-teacher-module/api/v1/teacher/educational-information/${id}`
    );
  }

  static getTeacherEducationInformationList() {
    return authAxios.get<TeacherEducationInformationResponceDto[]>(
      `/astanait-teacher-module/api/v1/teacher/educational-information`
    );
  }

  static createOrUpdateTeacherEducationInformation(TeacherEducationInformation: TeacherEducationInformationRequestDto) {
    return authAxios.post(
      `/astanait-teacher-module/api/v1/teacher/educational-information/create-or-update`,
      TeacherEducationInformation
    );
  }

  static deleteTeacherEducationInformation(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/educational-information/delete/${id}`);
  }

  // 404 (not found)
  static getTeacherImage() {
    // @ts-ignore
    let userId = JSON.parse(localStorage.getItem('user')).id;
    return authAxios.get(`/astanait-teacher-module/api/v1/teacher/image/${userId}`, { responseType: 'blob' });
  }

  static getTeacherImageById(userId: number) {
    return authAxios.get(`/astanait-teacher-module/api/v1/teacher/image/${userId}`, { responseType: 'blob' });
  }

  static uploadTeacherCertificate(certificateData: TeacherCertificateDto) {
    const formData = new FormData();

    certificateData.id && formData.append('id', certificateData.id.toString());
    formData.append('description', certificateData.description);
    formData.append('certificateType', certificateData.certificateType);
    certificateData.organizationName && formData.append('organizationName', certificateData.organizationName);
    certificateData.startDate && formData.append('startDate', certificateData.startDate);
    certificateData.endDate && formData.append('endDate', certificateData.endDate);
    certificateData.hours && formData.append('hours', certificateData.hours.toString());
    formData.append('fileId', certificateData.fileId);

    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/certificate/upload`, formData);
  }

  static getTeacherCertificates() {
    return authAxios.get<TeacherCertificateDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/certificate`);
  }
  // 404 (not found)
  static uploadTeacherProfileImage(imageData: any) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/upload/image`, imageData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static updateTeacherInfo(teacherData: {}) {
    return authAxios.put(`/astanait-teacher-module/api/v1/teacher/update/teacher`, teacherData);
  }

  static getTeacherPublicInfoById(userId: number) {
    return axios.get(
      `${this.baseURL}/astanait-teacher-module/api/v1/teacher/pps/get-teacher-by-user-id?user_id=${userId}`
    );
  }

  static getTeacherInfoById(userId: number) {
    return authAxios.get<TeacherDtoResponse>(
      `${this.baseURL}/astanait-teacher-module/api/v1/teacher/get-teacher-by-user-id?user_id=${userId}`
    );
  }

  static getAllTeachersPublic(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    const cacheKey = `get-all-teachers-public:${JSON.stringify(params)}`;

    // Check if the cache exists
    const cachedData = CacheService.getCache(cacheKey);
    if (cachedData) {
      return Promise.resolve(cachedData as any);
    }

    return CacheService.getWithCache(
      cacheKey,
      () => axios.get(`${this.baseURL}/astanait-teacher-module/api/v1/teacher/pps/get-all-teachers`, { params }),
      10 * 60 * 1000, // cache for 10 minutes
      params
    );
  }

  static getAllTeachers(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    const cacheKey = `get-all-teachers:${JSON.stringify(params)}`;

    // Check if the cache exists
    const cachedData = CacheService.getCache(cacheKey);
    if (cachedData) {
      return Promise.resolve(cachedData as any);
    }

    return CacheService.getWithCache(
      cacheKey,
      () => authAxios.get(`${this.baseURL}/astanait-teacher-module/api/v1/teacher/get-all-teachers`, { params }),
      10 * 60 * 1000, // cache for 10 minutes
      params
    );
  }

  static getPublicTeacherDetail(id: number) {
    return axios.get(`${this.baseURL}/astanait-teacher-module/api/v1/teacher/pps/get-teacher-info?teacher_id=${id}`);
  }

  static getTeacherPrincipal() {
    return authAxios.get<TeacherDtoResponse>(`/astanait-teacher-module/api/v1/teacher/get-teacher-by-principal`);
  }

  static getTeacherDisciplines() {
    return authAxios.get<DisciplineDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/discipline/get-disciplines-by-teacher-principal`
    );
  }

  static getGroupsByTeacherIdAndDisciplineId(disciplineId: number, year: number, term: number) {
    return authAxios.get<StudentGroupsDtoResponse[]>(
      `/astanait-student-module/api/v1/group/get-groups-by-discipline-and-teacher-principal?discipline_id=${disciplineId}&year=${year}&term=${term}`
    );
  }

  static getStudentsListByGroupIdAndDisciplineId(groupId: number, disciplineId: number) {
    return authAxios.get<StudentGroupsDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/assessment-report/summary-sheet-by-discipline-and-group-for-teacher?discipline_id=${disciplineId}&group_id=${groupId}`
    );
  }

  static editStudentMark(gradeId: number, grade: number) {
    return authAxios.put(
      `/astanait-office-module/api/v1/academic-department/academic-stream-student-grade/update-grade-for-teacher?academic_stream_student_grade_id=${gradeId}&grade=${grade}`
    );
  }

  static getPositionsList() {
    return authAxios.get<TeacherPositionDtoResponce[]>(`/astanait-teacher-module/api/v1/teacher/positions`);
  }

  static getTypesList() {
    return authAxios.get<TeacherTypeDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/types`);
  }

  static getStatusList() {
    return authAxios.get<TeacherStatusDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/status`);
  }

  static getPlatformTypesList() {
    return authAxios.get<TeacherStatusDtoResponse[]>(
      `/astanait-teacher-module/api/v1/teacher/scholar-information/types`
    );
  }

  static getTeacherImagePublic(userId: number) {
    return axios.get(`${this.baseURL}/astanait-teacher-module/api/v1/teacher/pps/image/${userId}`, {
      responseType: 'blob',
    });
  }

  static getAcademicStreams() {
    return authAxios.get<AcademicStreamWithGroupsDtoResponse[]>(
      `/astanait-office-module/api/v1/academic-department/assessment-report/all-academic-stream`
    );
  }

  static updateTeacherForStream(teacherId: number, id: number) {
    return authAxios.put(
      `/astanait-office-module/api/v1/academic-department/assessment-report/update-teacher?teacherId=${teacherId}&id=${id}`
    );
  }

  static getWorkExp() {
    return authAxios.get<TeacherWorkExpDto[]>(
      `/astanait-teacher-module/api/v1/teacher/work-experience`
    );
  }

  static createOrUpdateWorkExp(TeacherWorkExpDto: TeacherWorkExpDto) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/work-experience/create-or-update`, TeacherWorkExpDto);
  }

  static deleteWorkExp(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/work-experience/delete/${id}`);
  }

  static getDevelopments() {
    return authAxios.get<TeacherDevelopmentsDtoResponse[]>(
      `/astanait-teacher-module/api/v1/teacher/developments`
    );
  }

  static createOrUpdateDevelopment(teacherDevelopmentDtoRequest: TeacherDevelopmentsDtoRequest) {
    const formData = new FormData();

    teacherDevelopmentDtoRequest.id && formData.append('id', teacherDevelopmentDtoRequest.id.toString());
    formData.append('name', teacherDevelopmentDtoRequest.name);
    formData.append('fileId', teacherDevelopmentDtoRequest.fileId);


    return authAxios.post(
      `/astanait-teacher-module/api/v1/teacher/developments/create-or-update`,
      formData,
    );
  }


  static deleteDevelopment(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/developments/delete/${id}`);
  }



  // 404 (not found)
  static getTeacherResume() {
    // @ts-ignore
    let userId = JSON.parse(localStorage.getItem('user')).id;
    return authAxios({
      url: `/astanait-teacher-module/api/v1/teacher/resume/${userId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileExtension = response.headers['content-disposition'].split('.')[1].slice(0, -1);
      link.setAttribute('download', `Resume.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
    });
  }

  static getTeacherResumeById(userId: number) {
    return authAxios({
      url: `/astanait-teacher-module/api/v1/teacher/resume/${userId}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileExtension = response.headers['content-disposition'].split('.')[1].slice(0, -1);
      link.setAttribute('download', `Resume.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
    });
  }

  static uploadResume(resumeData: any) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/upload/resume`, resumeData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static getDegrees() {
    return authAxios.get<AcademicDegreeDtoResponse[]>('/astanait-teacher-module/api/v1/teacher/pps/get-degrees');
  }

  static getDegreeList() {
    return authAxios.get<TeacherDegreeDtoResponse[]>(
      `/astanait-teacher-module/api/v1/teacher/degree/by-user`
    );
  }


  static deleteDegree(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/degree/delete/${id}`);
  }

  static createDegree(teacherDegreeDto: TeacherDegreeDtoRequest) {
    const formData = new FormData();

    teacherDegreeDto.id && formData.append('id', teacherDegreeDto.id.toString());
    formData.append('speciality', teacherDegreeDto.speciality);
    formData.append('teacherEducationalDegree', teacherDegreeDto.teacherEducationalDegree.toString());
    formData.append('fileId', teacherDegreeDto.fileId);

    return authAxios.post(
      `/astanait-teacher-module/api/v1/teacher/degree/upload`, formData
    );
  }

  static getEducationalDegreeById(id: number) {
    return authAxios.get<TeacherEducationalDegree>(`/astanait-teacher-module/api/v1/teacher/degree/type/${id}`);
  }

  static getEducationalDegreesByType(id: number) {
    return authAxios.get<TeacherEducationalDegree[]>(`/astanait-teacher-module/api/v1/teacher/degree/list/${id}`);
  }

  static getLegalDocuments() {
    return authAxios.get<TeacherLegalDocsDtoResponce[]>(
      `/astanait-teacher-module/api/v1/teacher/legaldocs`
    );
  }

  static getLegalDocTypes() {
    return authAxios.get<TeacherPatentTypeDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/legaldocs/types`);
  }

  static createOrUpdateLegalDoc(legalDoc: TeacherLegalDocsDtoRequest) {
    const formData = new FormData();

    legalDoc.id && formData.append('id', legalDoc.id.toString());
    formData.append('typeId', legalDoc.typeId.toString());
    formData.append('fileId', legalDoc.fileId);

    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/legaldocs/upload`, formData);
  }

  static deleteLegalDoc(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/legaldocs/delete/${id}`);
  }

  static getScientificDegrees() {
    return axios.get<TeacherScientificDegreesDto[]>(`${process.env.REACT_APP_API_BASE_URL}/astanait-teacher-module/api/v1/teacher/pps/scientific-degree`)
  }

  static getAcademicStatuses() {
    return axios.get<TeacherScientificDegreesDto[]>(`${process.env.REACT_APP_API_BASE_URL}/astanait-teacher-module/api/v1/teacher/pps/academic-status`)
  }

  static getTeacherAdditionalInfo(userId?: number) {
    if (userId) {
      return authAxios.get<TeacherAdditionalInfoDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/additional/${userId}`)
    }
    else {
      return authAxios.get<TeacherAdditionalInfoDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/additional/principal`)
    }
  }

  static getAdditionalInfoTypes() {
    return authAxios.get<TeacherAdditionalInfoTypesDto[]>(`/astanait-teacher-module/api/v1/teacher/additional/types`)
  }

  static getAdditionalInfoCategories() {
    return authAxios.get<CatalogDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/additional/categories`)
  }

  static createOrUpdateAdditionalInfo(additionalInfo: TeacherAdditionalInfoDtoRequest) {
    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/additional/create`, additionalInfo);
  }

  static deleteAdditionalInfo(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/additional/delete/${id}`);
  }

  static getTeacherOrderTypes() {
    return authAxios.get<TeacherOrderTypeDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/order/types`);
  }

  static getTeacherOrderCategories(typeId: number) {
    return authAxios.get<TeacherOrderCategoryDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/order/${typeId}/categories`);
  }

  static getTeacherOrders() {
    return authAxios.get<TeacherOrderDtoResponse[]>(`/astanait-teacher-module/api/v1/teacher/order/all`);
  }

  static createOrUpdateTeacherOrder(order: TeacherOrderDtoRequest) {
    const formData = new FormData();

    order.id && formData.append('id', order.id.toString());
    formData.append('typeId', order.typeId.toString());
    formData.append('categoryId', order.categoryId.toString());
    formData.append('teacherId', order.teacherId.toString());
    formData.append('number', order.number);
    formData.append('name', order.name);
    formData.append('issueDate', order.issueDate.toString());
    formData.append('contingentDate', order.contingentDate.toString());
    order.file && formData.append('file', order.file);

    return authAxios.post(`/astanait-teacher-module/api/v1/teacher/order/create-or-update`, formData);
  }

  static deleteTeacherOrder(id: number) {
    return authAxios.delete(`/astanait-teacher-module/api/v1/teacher/order/delete/${id}`);
  }
}
