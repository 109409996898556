import { Button, DatePicker, Form, Input, Select, Space, Tag, Upload, UploadProps } from 'antd';
import React from 'react'
import UniversalTable from '../UniversalTeachersTable';
import { TeacherCertificateDto } from '../../../../interfaces/Teacher/TeacherCertificateDto';
import { TeacherService } from '../../../../services/TeacherService';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherCertificateDtoResponse } from '../../../../interfaces/Teacher/TeacherCertificateDtoResponse';
import { successNotification } from '../../../../helpers/successNotification';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload/interface';
import moment from 'moment';
import { FileService } from '../../../../services/file/FileService';

interface TeacherCertificatesTableProps {
    isPublic: boolean;
}

const TeacherCertificatesTable = ({ isPublic }: TeacherCertificatesTableProps) => {
    const { data: certificates, fetchData: fetchCertificates } = useFetchData<TeacherCertificateDtoResponse[]>(
        TeacherService.getTeacherCertificates, true
    );
    const [selectedType, setSelectedType] = React.useState<string>("");
    const [form] = Form.useForm();

    const uploadProps: UploadProps = {
        beforeUpload: (file) => {
            return false;
        },
        onChange: (e) => {
            form.setFieldsValue({ "fileId": e.fileList[0].originFileObj as RcFile });
        },
    }

    const addRecord = (record: TeacherCertificateDto) => {
        TeacherService.uploadTeacherCertificate(record)
            .then(() => fetchCertificates())
            .finally(() => successNotification('Certificate has been added!'))
    };

    const editRecord = (record: TeacherCertificateDto) => {
        TeacherService.uploadTeacherCertificate(record)
            .then(() => fetchCertificates())
            .finally(() => successNotification('Certificate has been updated!'))
    };

    const deleteRecord = (record: TeacherCertificateDto) => {
        TeacherService.deleteTeacherCertificate(record.id)
            .then(() => fetchCertificates())
            .finally(() => successNotification('Certificate has been deleted!'))
    };

    const handleDownload = (record: any) => {
        FileService.getFileById(record.fileId, "Certificate" as string);
    };


    return (
        <UniversalTable
            columns={[
                {
                    title: '#',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Certificate type',
                    dataIndex: 'certificateType',
                    key: 'certificateType',
                    render: (record: any) => {
                        switch (record) {
                            case 'INTERNSHIP':
                                return <Tag color={'blue'}>{'Internship'}</Tag>;
                            case 'QUALIFICATION':
                                return <Tag color={'blue'}>{'Qualification'}</Tag>;
                            case 'OTHER':
                                return <Tag color={'blue'}>{'Other'}</Tag>;
                            default:
                                return <></>;
                        }
                    },
                },
                {
                    title: 'Download file',
                    key: 'download',
                    render: (_: string, record: any) => (
                        <Space>
                            <Button icon={<DownloadOutlined />} onClick={() => handleDownload(record)}>Download</Button>
                        </Space>
                    ),
                }

            ]}
            dataSource={certificates ? certificates.map((certificate, i) => ({
                id: certificate.id,
                key: i + 1,
                description: certificate.description,
                organizationName: certificate.organizationName,
                startDate: certificate.startDate,
                endDate: certificate.endDate,
                hours: certificate.hours,
                certificateType: certificate.certificateType,
                fileId: certificate.fileId,
            })) : []}
            addRecord={(e: TeacherCertificateDto) => addRecord(e)}
            editRecord={(e: TeacherCertificateDto) => editRecord(e)}
            deleteRecord={(e: TeacherCertificateDto) => deleteRecord(e)}
            form={form}
            customForm={
                <Form form={form} layout='vertical'>
                    <Form.Item required name={"description"} label="Description">
                        <Input placeholder="Write description" />
                    </Form.Item>
                    <Form.Item required name={"certificateType"} label="Certificate type">
                        <Select placeholder="Select certificate type" onSelect={e => setSelectedType(e)}>
                            <Select.Option value="INTERNSHIP">Internship</Select.Option>
                            <Select.Option value="QUALIFICATION">Qualification</Select.Option>
                            <Select.Option value="OTHER">Other</Select.Option>
                        </Select>
                    </Form.Item>
                    {(selectedType === 'QUALIFICATION' || selectedType === 'INTERNSHIP')
                        &&
                        (
                            <>
                                <Form.Item required name={"organizationName"} label="Organization name">
                                    <Input placeholder="Enter your organization name" />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name={"startDate"}
                                    label="Start date"
                                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                                    getValueProps={(i) => ({ value: i ? moment(i) : null })}
                                >
                                    <DatePicker placeholder='Enter date' style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    name={"endDate"}
                                    label="End date"
                                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                                    getValueProps={(i) => ({ value: i ? moment(i) : null })}
                                >
                                    <DatePicker placeholder='Enter date' style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item required name={"hours"} label="Hours">
                                    <Input placeholder="Enter hours" />
                                </Form.Item>
                            </>
                        )}
                    <Form.Item required name={"fileId"} rules={[{ required: true }]} valuePropName="file">
                        <Upload {...uploadProps} >
                            <Button icon={<UploadOutlined />}>Upload file</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            }
        />
    )
}

export default TeacherCertificatesTable