import React from 'react'
import Page from '../../../../../ui/Page';
import { SurveyService } from '../../../../../services/student/SurveyService';
import { SurveyDtoResponse } from '../../../../../interfaces/Survey/SurveyDtoResponse';
import { SurveyQuestionsDtoResponse } from '../../../../../interfaces/Survey/SurveyQuestionsDtoResponse';
import SurveyPage from '../../SurveyPage';
import { Button, Card, Input, Radio, RadioChangeEvent, Space } from 'antd';
import { StudentSurveyAnswersDtoRequest } from '../../../../../interfaces/Survey/StudentSurveyAnswersDtoRequest';

interface Props {
    id: number;
}

const StudentSurveyDetails = ({ id }: Props) => {
    const [surveys, setSurveys] = React.useState<SurveyDtoResponse>();
    const [questions, setQuestions] = React.useState<SurveyQuestionsDtoResponse[]>([]);
    const [answers, setAnswers] = React.useState<StudentSurveyAnswersDtoRequest[]>([]);

    // React.useEffect(() => {
    // }, [answers])

    React.useEffect(() => {
        Promise.all([
            SurveyService.getSurveyById(id),
            SurveyService.getQuestions(id)
        ])
            .then(([surveys, questions]) => {
                setSurveys(surveys.data)
                setQuestions(questions.data)
                questions.data.map((question) => {
                    setAnswers((prevState) => [...prevState, {
                        academicStreamStudentId: undefined,
                        questionId: question.id,
                        answer: "",
                        surveyId: id
                    }])
                })
            })
    }, [id])

    const onChange = (e: RadioChangeEvent, questionId: number) => {
        setAnswers((prevState) =>
            prevState.map((answer) => (answer.questionId === questionId ? { ...answer, answer: e.target.value } : answer)),
        )
    };

    const onClickOther = (questionId: number) => {
        setAnswers((prevState) =>
            prevState.map((answer) => (answer.questionId === questionId ? { ...answer, answer: "" } : answer)),
        )
    }

    return (
        <Page title={surveys?.name}>
            {id === 1 ? (<SurveyPage />
            ) : (
                <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
                    {questions.map((question, i) => (
                        <Card title={question.question} key={i}>
                            {question.type.id === 4 && (
                                <Radio.Group size='large' onChange={(e) => onChange(e, question.id)} value={answers.find((answer) => answer.questionId === question.id)?.answer}>
                                    <Space direction="vertical">
                                        {question.variants.map((variant) => (
                                            <Radio key={variant.variant} value={variant.variant}>{variant.variant}</Radio>
                                        ))}
                                    </Space>
                                </Radio.Group>
                            )}
                            {question.type.id === 3 && (
                                <Input.TextArea
                                    onClick={() => onClickOther(question.id)}
                                    value={answers.find((answer) => answer.questionId === question.id)?.answer}
                                    onChange={(e) => setAnswers((prevState) =>
                                        prevState.map((answer) => (answer.questionId === question.id ? { ...answer, answer: e.target.value } : answer)),
                                    )}
                                    placeholder='Развернутый ответ'
                                    style={{ height: 100 }}
                                />
                            )}
                            {question.isHaveOther && (
                                <Input
                                    placeholder='Другое'
                                    style={{ marginTop: 15 }}
                                    onClick={() => onClickOther(question.id)}
                                    value={answers.find((answer) => answer.questionId === question.id)?.answer}
                                    onChange={(e) => setAnswers((prevState) =>
                                        prevState.map((answer) => (answer.questionId === question.id ? { ...answer, answer: e.target.value } : answer)),
                                    )}
                                />
                            )}
                        </Card>
                    ))}
                    <Button type='primary' onClick={() => { }}>Отправить</Button>
                </Space>
            )}
        </Page>
    )
}

export default StudentSurveyDetails