import { Button, Form, Input, Select } from 'antd'
import Page from '../../../../ui/Page';
import useApplications from '../useApplications';
import useFetchData from '../../../../hooks/useFetchData';
import { ApplicationTypeDtoResponse } from '../../../../interfaces/Applications/response/ApplicationTypeDtoResponse';
import { ApplicationService } from '../../../../services/ApplicationService';
import Center from '../../../../ui/Center';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ApplicationDtoRequest } from '../../../../interfaces/Applications/request/ApplicationDtoRequest';
import { ApplicationInfoDtoRequest } from '../../../../interfaces/Applications/request/ApplicationInfoDtoRequest';
import { useHistory } from 'react-router-dom';
import DataTypeComponent from '../DataTypeComponent';
import { MaskedInput } from 'antd-mask-input';



const CreateStudentApplicationPage = () => {
    const [form] = Form.useForm();
    const { data: types } = useFetchData<ApplicationTypeDtoResponse[]>(ApplicationService.getApplicationTypesUsed, true);
    const { data, methods } = useApplications({});
    const history = useHistory();

    return (
        <Page title={"Create application request"}>
            <Center lg={18}>
                <Form layout='vertical' form={form}
                    onFinish={(values) => {
                        const applicationDtoRequest: ApplicationDtoRequest = {
                            typeId: values.typeId,
                            lang: values.lang,
                            reason: values.reason,
                            phone: values.phone,
                        };

                        const applicationInfoDtoRequestList: ApplicationInfoDtoRequest[] =
                            data.applicationInfoTypes.map((type, i) => (
                                type.prefix === 'study_discount_type_item'
                                    ? type.id === 39 ?
                                        {
                                            applicationInfoTypesId: type.id,
                                            value: JSON.stringify(values[type.id]),
                                        } : {
                                            applicationInfoTypesId: type.id,
                                            value: values[type.id + 1]

                                        }
                                    : type.id === 39 ?
                                        {
                                            applicationInfoTypesId: type.id,
                                            value: JSON.stringify(values[type.id]),
                                        } : {
                                            applicationInfoTypesId: type.id,
                                            value: values[type.id],
                                        }
                            ));

                        const result = {
                            applicationDtoRequest,
                            applicationInfoDtoRequestList,
                        };
                        methods.handleCreate(result)
                            .finally(() => {
                                form.resetFields();
                                history.push('/applications');
                            });
                    }}
                >
                    <Form.Item
                        label="Language"
                        name="lang"
                        rules={[{ required: true, message: 'Please select a language!' }]}>
                        <Select onSelect={(value) => methods.handleSelectLanguage(value)}>
                            {data.languages?.map(lang => (
                                <Select.Option key={lang.id} value={lang.id}>
                                    {`${lang.name}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {data.selectedLanguage && (
                        <>
                            <Form.Item
                                label="Application type"
                                name="typeId"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'Please choose status!' }]}>
                                <Select onSelect={methods.handleSelectApplicationType}>
                                    {types?.map(type => (
                                        <Select.Option key={type.id} value={type.id}>
                                            {data.selectedLanguage === 3 ? type.nameEn : data.selectedLanguage === 1 ? type.nameRu : type.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Please write a comment!' }]}>
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please write a your phone!' }]}>
                                <MaskedInput mask={'+0-(000)-000-00-00'} placeholder="+_-(___)-___-__-__" />
                            </Form.Item>
                            {data.applicationInfoTypes.map((type, i) => (
                                <DataTypeComponent key={i} type={type} form={form} langId={data?.selectedLanguage ? data.selectedLanguage : 2} />
                            ))}
                            <Form.Item>
                                <Button type="primary" icon={<PlusCircleOutlined />} htmlType="submit">
                                    Create
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Center>
        </Page>

    )
}

export default CreateStudentApplicationPage