import { Table } from "antd"
import ReactLinkify from "react-linkify";
import { TeacherArticleDtoResponse } from "../../../../interfaces/Teacher/TeacherArticleDtoResponse";
import { ColumnsType } from "antd/lib/table";

interface Props {
    datasource?: TeacherArticleDtoResponse[];
}

const PublicTeacherArticlesTable = ({ datasource }: Props) => {
    const columns: ColumnsType<TeacherArticleDtoResponse> = [
        {
            title: "#",
            dataIndex: "id",
            key: "id"
        },
        {
            title: 'Full citation: Authors, Title, Journal, Year, Pages. DOI',
            dataIndex: 'information',
            width: '40%',
            key: 'information',
            render: (information: any) => {
                return (
                    <ReactLinkify>
                        {information}
                    </ReactLinkify>
                )
            }
        },
        {
            title: "Type",
            dataIndex: "articleType",
            key: "articleType",
            render: (articleType: any) => {
                return (
                    <>
                        {articleType.name}
                    </>
                )
            }
        },
        {
            title: 'Link or DOI',
            dataIndex: 'linkOrDoi',
            key: 'linkOrDoi',
            render: (_, record, idx) => {
                return <ReactLinkify>{record.linkOrDoi}</ReactLinkify>;
            },
        },
        {
            title: 'Publication Date',
            dataIndex: 'publicationDate',
            key: 'publicationDate',
            render: (_, record, idx) => {
                return <>{record.publicationDate}</>;
            },
        },
    ];

    return (
        <>
            <Table bordered columns={columns} dataSource={datasource}
            />
        </>
    )
}

export default PublicTeacherArticlesTable
