/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import Page from '../../../ui/Page'
import RowSpan from '../../../ui/RowSpan'
import { Button, DatePicker, Form, Input, Modal, Select, Space, Table } from 'antd'
import useDictionary from '../../../hooks/useDictionary'
import { DepartmentDtoResponse } from '../../../interfaces/DepartmentDtoResponse'
import { ReportService } from '../../../services/ReportService'
import { ReportDtoResponse } from '../../../interfaces/Reports/response/ReportDtoResponse'
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons'
import useModal from '../../../ui/Modal/useModal'
import { ReportParameterDtoResponse } from '../../../interfaces/Reports/response/ReportParameterDtoResponse'
import { VedomostiService } from '../../../services/VedomostiService'
import { AbiturCatalogDtoResponse } from '../../../interfaces/AbiturCatalogDtoResponse'
import { successNotification } from '../../../helpers/successNotification'
import { ParameterDtoRequest } from '../../../interfaces/Reports/request/ParameterDtoRequest'
import { ReportColumnsDtoResponse } from '../../../interfaces/Reports/response/ReportColumnsDtoResponse'
import moment from 'moment'

const ReportsListPage = () => {
    const { dictionary } = useDictionary({ fetchDepartments: true, fetchStudentStatus: true, fetchStudentStudyStatus: true });
    const { isModalOpen, showModal, handleCancel } = useModal();
    const { isModalOpen: isColumnsModalOpen, showModal: showColumnsModal, handleCancel: handleCancelColumns } = useModal();

    const [modalForm] = Form.useForm();
    const [reports, setReports] = useState<ReportDtoResponse[]>([]);
    const [reportParameters, setReportParameters] = useState<ReportParameterDtoResponse[]>([]);
    const [reportColumns, setReportColumns] = useState<ReportColumnsDtoResponse[]>([]);

    const [selectData, setSelectData] = useState<Record<string, AbiturCatalogDtoResponse[]>>({});
    const [selectedReport, setSelectedReport] = useState<ReportDtoResponse | null>(null);

    useEffect(() => {
        ReportService.getReports({}).then((response) => {
            setReports(response.data);
        })
    }, [])

    useEffect(() => {
        reportParameters.forEach(parameter => {
            switch (parameter.prefix) {
                case "course":
                    setSelectData(prevData => ({
                        ...prevData,
                        "course": dictionary?.trimesters?.map((trim) => ({ id: trim.id, name: `${trim.trimester} курс` }))
                    }))
                    break;
                case "academicDegreeId":
                    setSelectData(prevData => ({
                        ...prevData,
                        "academicDegreeId": dictionary?.degreeList?.map((degree) => ({ id: degree.id, name: degree.title }))
                    }))
                    break;
                case "status":
                    setSelectData(prevData => ({
                        ...prevData,
                        "status": dictionary?.studentStatusTypeList?.map((status) => ({ id: status.id, name: status.nameRu })) || []
                    }))
                    break;
                case "studyStatus":
                    setSelectData(prevData => ({
                        ...prevData,
                        "studyStatus": dictionary?.studentStudyStatusTypeList?.map((status) => ({ id: status.id, name: status.nameRu })) || []
                    }))
                    break;
                case "financingId":
                    setSelectData(prevData => ({
                        ...prevData,
                        "financingId": dictionary?.financingShortList?.map((financing) => ({ id: financing.id, name: financing.value }))
                    }))
                    break;
                case "department":
                    setSelectData(prevData => ({
                        ...prevData,
                        "department": dictionary?.departments?.map((department) => ({ id: department.id, name: department.titleEn })) || []
                    }))
                    break;
                case "defenseYear":
                    setSelectData(prevData => ({
                        ...prevData,
                        "defenseYear": dictionary?.defenseYearList?.map((defenseYear) => ({ id: defenseYear.year, name: defenseYear.year.toString() })) || []
                    }))
                    break;
            }
        });
    }, [reportParameters, dictionary]);

    const handleSelectChange = (prefix: string, value: any) => {
        switch (prefix) {
            case "academicDegreeId":
                VedomostiService.getEducationalProgramsByDegree(value).then((response) => {
                    setSelectData(prevData => ({
                        ...prevData,
                        "educationProgramId": response.data.map((item, i) => {
                            return {
                                id: item.id,
                                name: item.titleKz
                            }
                        })
                    }))
                })
                break;
            case "educationProgramId":
                if (reportParameters.find(x => x.prefix === "groupId")) {
                    VedomostiService.getGroupsByCourseAndEducationalProgram(modalForm.getFieldValue('course'), value)
                        .then((response) => {
                            setSelectData(prevData => ({
                                ...prevData,
                                "groupId": response.data.map((item, i) => {
                                    return {
                                        id: item.id,
                                        name: item.title
                                    }
                                })
                            }))
                        })
                }
                break;
            default:
                break;
        }
    }

    const handleClear = (prefix: string) => {
        modalForm.setFieldsValue({ [prefix]: undefined });
        switch (prefix) {
            case "course":
                setSelectData(prevData => ({
                    ...prevData,
                    "academicDegreeId": [],
                    "educationProgramId": [],
                    "groupId": []
                }))
                modalForm.setFieldsValue({ "academicDegreeId": undefined, "educationProgramId": undefined, "groupId": undefined });
                break;
            case "academicDegreeId":
                setSelectData(prevData => ({
                    ...prevData,
                    "educationProgramId": []
                }))
                modalForm.setFieldsValue({ "educationProgramId": undefined });
                break;
            case "educationProgramId":
                setSelectData(prevData => ({
                    ...prevData,
                    "groupId": []
                }))
                modalForm.setFieldsValue({ "groupId": undefined });
                break;
            default:
                break;
        }
    }

    const handleSearchReports = (values: any) => {
        ReportService.getReports(values).then((response) => {
            setReports(response.data);
        })
    }

    const handleShowColumnsModal = (report: ReportDtoResponse) => {
        ReportService.getReportColumns(report.id).then(({ data }) => {
            if (data.length > 0) {
                showColumnsModal()
                setReportColumns(data)
            }
        })
    }

    const handleShowModal = (report: ReportDtoResponse) => {
        ReportService.getReportParameters(report.id)
            .then((response) => {
                if (response.data.length > 0) {
                    showModal()
                    setReportParameters(response.data)
                    setSelectedReport(report)
                }
                else {
                    ReportService.generateReport(report.id, report.name_ru, [])
                        .then(() => {
                            successNotification("Отчет успешно сгенерирован")
                        });
                }
            })
    }

    const handleSubmit = (parameters: ParameterDtoRequest[]) => {
        parameters = parameters.filter((parameter) => parameter.value !== undefined && parameter.value !== null && parameter.value !== "");
        ReportService.generateReport(selectedReport?.id || 0, selectedReport?.name_ru || '', parameters)
            .then(() => {
                successNotification("Отчет успешно сгенерирован")
            })
            .finally(() => {
                handleCancel();
            });
    }



    const columns = [
        {
            title: 'Название отчета',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Департамент',
            dataIndex: 'department',
            key: 'department',
            render: (text: any, record: any) => {
                return record.department?.titleRu
            }
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Колонки',
            dataIndex: 'columns',
            key: 'columns',
            render: (text: any, record: any) => (
                <Space size={"middle"}>
                    <Button type="primary" icon={<OrderedListOutlined />} onClick={() => handleShowColumnsModal(record)}>Смотреть</Button>
                </Space>
            )
        },
        {
            title: 'Скачать',
            dataIndex: 'download',
            key: 'download',
            render: (text: any, record: any) => (
                <Space size={"middle"}>
                    <Button type="primary" icon={<UploadOutlined />} onClick={() => handleShowModal(record)}>Скачать</Button>
                </Space>
            )
        }
    ];

    return (
        <Page title={"Страница отчетов"} subtitle={"Страница для получения отчетов в .xlsx формате из базы"}>
            <RowSpan left={
                <Space>
                    <Form onFinish={e => handleSearchReports(e)}>
                        <Form.Item name="search" label="Название отчета">
                            <Input placeholder='Напишите название отчета' allowClear />
                        </Form.Item>
                        <Form.Item name="departmentId" label="Департамент">
                            <Select placeholder="Выберите департамент" allowClear>
                                {dictionary?.departments?.map((department: DepartmentDtoResponse) => <Select.Option key={department.id} value={department.id}>{department.titleRu}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit">Найти отчет</Button>
                        </Form.Item>
                    </Form>
                </Space>
            } />

            <Modal open={isColumnsModalOpen} onCancel={handleCancelColumns} closeIcon={<></>} onOk={handleCancelColumns} okButtonProps={{
                hidden: true
            }}>
                <Table
                    rowKey={(record) => record.id}
                    showHeader={false}
                    columns={[
                        {
                            title: 'Название колонки',
                            dataIndex: 'name',
                            key: 'name',
                        },
                    ]}
                    bordered
                    dataSource={reportColumns}
                    pagination={false}
                />
            </Modal>

            <Modal open={isModalOpen} onCancel={handleCancel} onOk={modalForm.submit} okText="Скачать отчет">
                <Form form={modalForm} layout='vertical' onFinish={e => handleSubmit(Object.entries(e).map(([prefix, value]) => ({ prefix, value })))}>
                    {reportParameters.sort((a, b) => a.id - b.id).map((parameter: ReportParameterDtoResponse) => {
                        switch (parameter.valueType) {
                            case "integer":
                                return (
                                    <Form.Item name={parameter.prefix} label={parameter.name_ru}>
                                        <Select
                                            allowClear
                                            placeholder={`Пожалуйста выберите '${parameter.name_ru}'`}
                                            onSelect={(e) => handleSelectChange(parameter.prefix, e)}
                                            onClear={() => handleClear(parameter.prefix)}
                                        >
                                            {selectData[parameter.prefix]?.map((data: any, i: number) => (
                                                <Select.Option key={i} value={data.id}>
                                                    {data.name || data}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                );
                            case "string":
                                return (
                                    <Form.Item name={parameter.prefix} label={parameter.name_ru}>
                                        <Input />
                                    </Form.Item>
                                );
                            case "boolean":
                                return (
                                    <Form.Item name={parameter.prefix} label={parameter.name_ru}>
                                        <Select allowClear placeholder={`Пожалуйста выберите '${parameter.name_ru}'`}>
                                            {dictionary?.booleanSelectList?.map((item) => (
                                                <Select.Option key={item.key} value={item.id}>{item.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                );
                            case "date":
                                return (
                                    <Form.Item
                                        label={parameter.name_ru}
                                        name={parameter.prefix}
                                        getValueProps={(i) => i ? { value: moment(i) } : { value: '' }}
                                        getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                                    >
                                        <DatePicker placeholder="Выберите дату" allowClear={false} />
                                    </Form.Item>
                                );
                            default:
                                break;
                        }
                    })}
                </Form>
            </Modal>

            {reports.length > 0 &&
                <Table rowKey={(record) => record.id} columns={columns} bordered dataSource={reports} />
            }
        </Page>
    )
}

export default ReportsListPage