/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, notification, Space, Timeline, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { OrderDtoResponse } from '../../../interfaces/Order/OrderDtoResponse';
import { OrdersService } from '../../../services/OrdersService';
import TableView from '../StudentsDetailsPage/TableView';
import TimelineView from '../StudentsDetailsPage/TimelineView';

interface Props {
  studentId?: number;
}

const ProfileOrders = ({ studentId }: Props) => {

  const [orders, setOrders] = useState<OrderDtoResponse[]>([]);
  const [tableViewOpen, setTableViewOpen] = useState<boolean>(true);
  const [timelineViewOpen, setTimelineViewOpen] = useState<boolean>(false);

  useEffect(() => {
    OrdersService.getOrderListByStudent(studentId)
      .then(({ data }) => setOrders(data))
  }, [studentId]);

  const openTableView = () => {
    setTimelineViewOpen(false);
    setTableViewOpen(true);
  };

  const openTimelineView = () => {
    setTableViewOpen(false);
    setTimelineViewOpen(true);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <a href="#" onClick={openTableView}>
          Табличный
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a href="#" onClick={openTimelineView}>
          Графический
        </a>
      ),
      key: '1',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Type',
      dataIndex: 'orderType',
      key: 'orderType',
    },
    {
      dataIndex: 'operation',
      key: 'operation',
      render: (text: any, record: any) => (
        <Space>
          <Button type="default" onClick={() => handleDownload(record.orderId, record.name)}>
            <DownloadOutlined />
          </Button>
        </Space>
      ),
      width: '5%',
    },
  ];

  const handleDownload = (orderId: number, name: string) => {
    OrdersService.downloadOrder(orderId, name)
      .then(() => {
        notification.success({ message: 'Приказ успешно был скачан!' });
      });
  };

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <Dropdown menu={{ items }} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Button>
              Вид
              <DownOutlined />
            </Button>
          </a>
        </Dropdown>
      </Space>
      {timelineViewOpen && (
        <TimelineView
          items={orders}
          render={(item) => (
            <Timeline.Item style={{ margin: 0 }} key={item.id}>
              <Typography.Title level={5}>
                {item.name}, {item.number} <br />
                <Typography.Text type="secondary">{item.issueDate.toString()}</Typography.Text>
              </Typography.Title>
            </Timeline.Item>
          )}
        />
      )}
      {tableViewOpen && (
        <TableView
          columns={columns}
          renderItem={orders.map((order) => ({
            key: order.id,
            orderId: order.id,
            name: order.name,
            issueDate: order.issueDate,
            number: order.number,
            orderType: order.orderType.nameEn,
          }))}
        />
      )}
    </>
  );
};

export default ProfileOrders;
