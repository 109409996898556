import { useEffect, useState } from 'react';
import moment from 'moment';
import { NotificationService } from '../../../services/NotificationService';
import { NotificationDtoRsponse } from '../../../interfaces/Notification/response/NotificationDtoResponse';
import ReactLinkify from 'react-linkify';


const useNotificationPage = () => {
    const [notification, setNotification] = useState<NotificationDtoRsponse[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);



    // const onDeleteAcademicMobility = (academicMobilityId: number) => {
    //     AcademicMobilityService.deleteAcademicMobility(academicMobilityId)
    //         .then(() => setShouldRerender(!shouldRerender))
    //         .catch((error) => console.error(error));
    // };

    useEffect(() => {
        const zeroBasedPage = currentPage - 1;
        NotificationService.getAllNotification(zeroBasedPage)
            .then((response) => {
                setNotification(response.data.data);
                setTotalPages(response.data.total_pages);
                console.log(response.data)
            })
            .catch((error) => console.error(error));
    }, [currentPage]);

    const componentDecorator = (href: any, text: any, key: any) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>

    );

    const column = [
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (text: any, record: any) => (record && record.message && <ReactLinkify componentDecorator={componentDecorator}>{record.message}</ReactLinkify>),

        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: any, record: any) => (record && record.createdDate ? moment(record.createdDate).format('DD.MM.YYYY') : 'Не найдено'),
        },
        {
            title: 'Is Read',
            dataIndex: 'isRead',
            key: 'isRead',
            render: (text: any, record: any) => (record && record.isRead === true ? 'Yes' : 'No')
        },
        {
            title: 'Read at',
            dataIndex: 'readAt',
            key: 'readAt',
            width: 180,
            render: (text: any, record: any) => (record && record.readAt ? moment(record.createdDate).format('DD.MM.YYYY HH:mm') : '')
        },
        {
            title: 'Is read in Moodle',
            dataIndex: 'moodleMessageIsRead',
            key: 'moodleMessageIsRead',
            render: (text: any, record: any) => (record && record.moodleMessageIsRead === true ? 'Yes' : 'No')
        },
        {
            title: 'Read at in Moodle',
            dataIndex: 'moodleMessageReadAt',
            key: 'moodleMessageReadAt',
            render: (text: any, record: any) => (record && record.moodleMessageReadAt ? moment(record.createdDate).format('DD.MM.YYYY HH:mm') : '')
        },
        // {
        //     title: 'Действия',
        //     dataIndex: 'actions',
        //     key: 'actions',
        //     width: 120,
        //     render: (text: any, record: any) => (
        //         <Space size="middle">
        //             <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
        //                 <Button className={styles.deleteButton} onClick={() => onDeleteAcademicMobility(record.key)}>
        //                     <DeleteTwoTone twoToneColor="#ff0000" />
        //                 </Button>
        //             </AuthenticatedContent>
        //             <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
        //                 <Button href={`/academic-mobility/edit/${record.key}`}>
        //                     <EditTwoTone />
        //                 </Button>
        //             </AuthenticatedContent>
        //         </Space>
        //     ),
        // },
    ]
    return {
        notification,
        column,
        currentPage,
        totalPages,
        setCurrentPage
    };
};

export default useNotificationPage;