import Title from 'antd/es/typography/Title';
import PageWrapper from '../../../ui/PageWrapper';
import { Alert, Space, Table, Typography } from 'antd';
import './appointment.css';
import usePsychologistAppointmentPage from './usePsychologistAppointmentPage';
const { Text } = Typography;
const PsychologicalAppointmentPage = () => {
  const { columns, data } = usePsychologistAppointmentPage();

  return (
    <PageWrapper>
      <section>
        <header>

          <Title level={1}>Appointment with a psychologist</Title>
          <Alert style={{ width: 1000 }} message='C 01.07.24 до 31.07.24 прием у психолога проходит в онлайн формате. Запись на онлайн консультацию - по ссылке в Телеграм  yuten_psy"' type="info" />
          <Title level={4}>Данные о психологе</Title>
          <Space direction="vertical" style={{
            marginBottom: '20px',
          }}>
            <Text code>ФИО: Yuliya Ten</Text>
            <Text code>Почта: Yu.ten@astanait.edu.kz</Text>
          </Space>
        </header>
        {/* <Title level={4}>Приемы психолога временно проводятся онлайн. Можете записаться через телеграм @yuten_psy </Title> */}
        <Table
          bordered
          showHeader={false}
          scroll={{ x: 1000 }}
          columns={columns.appointmentsColumns}
          dataSource={data.filteredAppointmentDates}
        />
        <Title level={3}>My Appointments</Title>
        <Table columns={columns.userAppointmentsColumns} scroll={{ x: 1000 }}
          dataSource={(data.filteredUserAppointments).reverse()} />
      </section>
    </PageWrapper>
  );
};

export default PsychologicalAppointmentPage;
