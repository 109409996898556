import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Typography, Spin, Row, Col, Modal, Tabs } from 'antd';
import { InfoCircleOutlined, WindowsOutlined } from '@ant-design/icons';
import '../../style/login.css';
import AuthService from '../../services/authService';
import { Constants } from '../../common/constants';
import { Link } from 'react-router-dom';
import UserService from '../../services/userService';
import { successNotification } from '../../helpers/successNotification';
import type { TabsProps } from 'antd';
import { EnglishContent, KazakhContent, RussianContent } from './UserAgreement';
import { UserDtoResponse } from '../../interfaces/UserDtoResponse';
const Login = () => {
  const PUBLIC_URL = process.env.PUBLIC_URL;
  const history = useHistory();
  const [errorMesage, setErrorMessage] = useState('');
  const urlParam = new URLSearchParams(window.location.search);
  const queryCode = urlParam.get('code');
  const [isLoading, setLoading] = useState(false);
  // const agreeWithUserAgreement = useRef(UserService.getCurrentUser());
  const { confirm } = Modal;
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Қазақша',
      children: <KazakhContent />,
    },
    {
      key: '2',
      label: 'На русском',
      children: <RussianContent />,
    },
    {
      key: '3',
      label: 'In English',
      children: <EnglishContent />,
    },
  ];
  useEffect(() => {
    if (!!localStorage.getItem('token')) {
      UserService.logOut();
    }
    if (queryCode !== null) {
      setLoading(true);
      AuthService.login(queryCode)
        .then((response: any) => {
          setLoading(false);

          if (response.data.agreeWithUserAgreement == false) {
            confirm({
              title: 'Do you agree with User agreement?',
              icon: <InfoCircleOutlined style={{ color: '#08c' }} />,
              content: <Tabs style={{ maxHeight: '300px', overflowY: 'auto' }} defaultActiveKey="1" items={items} />,
              okText: 'Yes',
              cancelText: 'No',
              width: 800,
              onOk() {
                UserService.addUserAgreement(true)
                  .then(() => {
                    successNotification('You agree With User Agreement!')
                    const storedUserString = localStorage.getItem('user');
                    const user: UserDtoResponse | null = storedUserString ? JSON.parse(storedUserString) : null;
                    if (user !== null) {
                      user.agreeWithUserAgreement = true;
                    }
                    localStorage.setItem('user', JSON.stringify(user));
                  })
                  .finally(() => {
                    history.push('/');
                  });
              },
              onCancel() {
                UserService.logOut()
              },
            });
          }
          else {
            history.push('/');
          }
        })
        .catch((error: any) => {
          setLoading(false);
          setErrorMessage(error);
        });
    }
  }, []);

  return (
    <div className="main-container">
      <Row>
        <Col flex="auto"></Col>
        <Col flex={0}>
          <div > <img className="logo-container" src={`${PUBLIC_URL}/assets/logo.jpg`} alt="logo" /></div>
          {/* <Form className="form-itself">
            <Form.Item name="username">
              <Input
                size="large"
                prefix={<UserOutlined style={{ color: 'grey' }} />}
                placeholder="Username"
                className="site-form-item-icon"
              />
            </Form.Item>
            <Form.Item name="password">
              <Password size="large" prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" style={{ width: '100%' }}>
                Log in
              </Button>
            </Form.Item>
            <Form.Item>
            </Form.Item>
          </Form> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {isLoading ? (
              <Spin />
            ) : (
              <div>
                <Row>
                  <Button
                    style={{
                      width: '300px',
                      margin: '25px auto',
                    }}
                    size="large"
                    type="primary"
                    href={Constants.LOGIN_REDIRECT_URL}
                    icon={<WindowsOutlined />}
                  >
                    Sign in with corporate E-mail
                  </Button>
                </Row>
                <Row>
                  <a style={{
                    margin: '25px auto',

                  }}
                    href={`${PUBLIC_URL}/assets/Instructions for signing up DU.docx`} download>Click to download Instruction</a>
                </Row>
              </div>
            )}
            {errorMesage && (
              <Typography.Title level={5} type="danger" style={{ textAlign: 'center', marginTop: 20 }}>
                Something went wrong, try again or contact the administration!
              </Typography.Title>
            )}
          </div>
        </Col>
        <Col flex="auto"></Col>
      </Row>
    </div>
  );
};

export default Login;
