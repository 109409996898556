import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { PersistentStateConstants } from '../../../../PersistentStateConstants';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import { PersistentStateService } from '../../../../services/PersistentStateService';
import { TranscriptService } from '../../../../services/TranscriptService';

const useAddAppointmentPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [studentsList, setStudentsList] = useState<StudentDtoResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [studentsCount, setStudentsCount] = useState<number>(0);

  useEffect(() => {
    if (PersistentStateService.hasInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)) {
      const searchQuery: any = JSON.parse(
        // @ts-ignore
        PersistentStateService.getInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY)
      );
      setSearchValue(searchQuery);
    }
  }, []);

  useEffect(() => {
    PersistentStateService.setInputFieldsInfo(PersistentStateConstants.STUDENT_DETAILS_SEARCH_QUERY, searchValue);
  }, [searchValue]);

  const handleSearchStudents = (fullname: string) => {
    if (!fullname) {
      notification.error({ message: 'Введите имя в поле поиска!' });
      return;
    }
    setLoading(true);
    TranscriptService.getStudentByName(fullname)
      .then(({ data }) => {
        setStudentsList(data);
        if (data.length === 0) notification.error({ message: `Не найдено ни одного студента` });
        else notification.success({ message: `Найдено ${data.length} студентов` });
        setStudentsCount(data.length);
      })
      .finally(() => setLoading(false));
  };

  return {
    searchValue,
    setSearchValue,
    studentsList,
    setStudentsList,
    loading,
    setLoading,
    handleSearchStudents,
    studentsCount,
  };
};

export default useAddAppointmentPage;
