import { useEffect, useState } from "react";
import { Button, DatePicker, DatePickerProps, Form, Input, Space, Tag, Upload } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { CloseOutlined, DownloadOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import { TeacherService } from "../../../services/TeacherService";
import Spinner from "../../../ui/Spinner";
import moment from "moment";
import InputMask from "react-input-mask";
import { TeacherDtoResponse } from "../../../interfaces/Teacher/TeacherDtoResponse";
import { TeacherDtoRequest } from "../../../interfaces/Teacher/TeacherDtoRequest";
import { useForm } from "antd/es/form/Form";
import { useTheme } from '../../../themes/useTheme';
import { TeacherAdditionalInfoDtoResponse } from "../../../interfaces/Teacher/TeacherAdditionalInfoDtoResponse";
import { successNotification } from "../../../helpers/successNotification";

interface TeacherInfoSectionProps {
    teacherInfo: TeacherDtoResponse;
    teacherAdditionalInfo?: TeacherAdditionalInfoDtoResponse[];
    updateTeacher: Function;
}

interface TeacherArray {
    id: number;
    array: string,
}

export default function TeacherInfoSection({ teacherInfo, updateTeacher, teacherAdditionalInfo }: TeacherInfoSectionProps) {
    const [editToggleInfo, setEditToggleInfo] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = useForm();
    const [birthDate, setBirthDate] = useState<Date>(new Date())
    const [isDarkMode] = useTheme();
    const [taughtCourses, setTaughtCourses] = useState<TeacherArray[]>([]);
    const [scientificInterests, setScientificInterests] = useState<TeacherArray[]>([]);
    const [resumeFile, setResumeFile] = useState();


    useEffect(() => {
        if (teacherInfo.scientificInterests) {
            teacherInfo.scientificInterests.split(',').map((scientificInterest, i) => {
                return (
                    setScientificInterests(prevArray => [...prevArray, { id: i, array: scientificInterest }])
                );
            })
        }
        if (teacherInfo.taughtCourses) {
            teacherInfo.taughtCourses.split(',').map((taughtCourse, i) => {
                return (
                    setTaughtCourses(prevArray => [...prevArray, { id: i, array: taughtCourse }])
                );
            })
        }
    }, [teacherInfo.scientificInterests, teacherInfo.taughtCourses])

    const updateInfo = (teacherData: TeacherDtoRequest) => {
        // setIsLoading(true)
        teacherData.birthDate = birthDate;
        teacherData.scientificInterests = scientificInterests.map((v) => v.array).toString();
        teacherData.taughtCourses = taughtCourses.map((v) => v.array).toString();

        console.log(teacherData)
        // const additionalInfo = Object.entries(teacherData.additionalInfo)
        //     .map(([type, value]) => {
        //         return {
        //             type,
        //             value
        //         }
        //     });

        // teacherData.additionalInfo = additionalInfo.map(({ type, value }) => ({ type, value: value.toString() }));
        // TeacherService.updateTeacherInfo(teacherData).then(res => {
        //     successNotification({ message: "Profile information updated!" })
        //     setEditToggleInfo(false)
        //     setIsLoading(false)
        // })
        //     .finally(() => updateTeacher())
    }

    const handleDownloadResume = () => {
        TeacherService.getTeacherResume().then(res => {
            successNotification({ message: "Resume updated!" })
        })
    };

    if (isLoading) {
        return <Spinner size={"large"} />
    }

    const handleUpload = () => {
        const formData = new FormData();
        // @ts-ignore
        formData.append("resume", resumeFile)
        TeacherService.uploadResume(formData).then(res => {
            successNotification({ message: "Resume updated!" })
        })
    };

    const props = {
        beforeUpload: (file: any) => {
            setResumeFile(file)
            return false;
        },
    };


    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setBirthDate(new Date(dateString))
    };

    return (
        <Space direction={"vertical"} style={{ width: "100%" }}>
            <Space direction={"vertical"} style={{ width: "100%" }}>
                {
                    editToggleInfo ? (
                        <div>
                            <Form form={form} layout="vertical" onFinish={e => console.log(e)} initialValues={{ remember: true }} onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}>
                                <Space direction={"vertical"}>
                                    <Space direction={"horizontal"} align={"start"} size={50} style={{ width: "100%" }}>
                                        <Space size={20} direction={"vertical"} style={{ width: "100%", maxWidth: "100%" }}>
                                            <Form.Item name={"nameKz"} label={"First name"}>
                                                <Input
                                                    defaultValue={teacherInfo.nameKz}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>

                                            <Form.Item name={"surnameKz"} label={"Last name"}>
                                                <Input
                                                    defaultValue={teacherInfo.surnameKz}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>

                                            <Form.Item name={"patronymicKz"} label={"Patronymic"}>
                                                <Input
                                                    defaultValue={teacherInfo.patronymicKz}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>
                                        </Space>

                                        <Space size={20} direction={"vertical"} style={{ width: "100%", maxWidth: "100%" }}>
                                            <Form.Item name={"nameEn"} label={"First name (English)"}>
                                                <Input
                                                    defaultValue={teacherInfo.nameEn}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>

                                            <Form.Item name={"surnameEn"} label={"Last name (English)"}>
                                                <Input
                                                    defaultValue={teacherInfo.surnameEn}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>
                                        </Space>

                                        <Space size={20} direction={"vertical"} style={{ width: "100%", maxWidth: "100%" }}>
                                            <Form.Item name={"iin"} label={"IIN"}>
                                                <Input
                                                    defaultValue={teacherInfo.iin}
                                                    maxLength={12}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>

                                            <Form.Item name={"birthDate"} label={"Birth Date"}>
                                                <DatePicker
                                                    defaultValue={
                                                        (teacherInfo?.birthDate && moment(teacherInfo.birthDate, 'YYYY-MM-DD')) || ''
                                                    }
                                                    picker={"date"}
                                                    onChange={onChange}
                                                    size={"large"}
                                                    style={{ width: "100%", borderRadius: "10px" }} />
                                            </Form.Item>

                                            <Form.Item name={"phone"} label={"Phone"}>
                                                <InputMask
                                                    mask={"+7 (999) 999-99-99"}
                                                    autoComplete={"off"}
                                                    defaultValue={teacherInfo.phone}
                                                    style={isDarkMode ? {
                                                        backgroundColor: "black",
                                                        width: "100%",
                                                        borderRadius: "10px",
                                                        padding: "6.5px 11px 6.5px",
                                                        border: "1px solid #434343"
                                                    } : {
                                                        width: "100%",
                                                        borderRadius: "10px",
                                                        padding: "6.5px 11px 6.5px",
                                                        border: "1px solid #434343"
                                                    }}
                                                >
                                                </InputMask>
                                            </Form.Item>
                                        </Space>
                                    </Space>
                                    <Space direction={"vertical"}>
                                        <Form.Item name={"scientificInterests"} label={"Scientific interests"}>
                                            <Space wrap size={5} style={{ marginBottom: "20px" }}>
                                                {scientificInterests?.map((interest, i) => (
                                                    <Tag key={i} icon={<CloseOutlined />} onClick={(e) => {
                                                        setScientificInterests((current) =>
                                                            current.filter((element) => element.id !== i)
                                                        )
                                                    }}>{interest.array}</Tag>
                                                ))}
                                            </Space>
                                            <Input size="large"
                                                onPressEnter={(e) => {
                                                    e.currentTarget.value.length > 0 &&
                                                        setScientificInterests(oldArray => [...oldArray, { id: oldArray.length, array: e.currentTarget.value }])
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item name={"taughtCourses"} label={"Teaching disciplines"}>
                                            <Space wrap size={5} style={{ marginBottom: "20px" }}>
                                                {taughtCourses?.map((taught, i) => (
                                                    <Tag key={i} icon={<CloseOutlined />} onClick={(e) => {
                                                        setTaughtCourses((current) =>
                                                            current.filter((element) => element.id !== i)
                                                        )
                                                    }}>{taught.array}</Tag>
                                                ))}
                                            </Space>
                                            <Input size="large"
                                                onPressEnter={(e) => {
                                                    e.currentTarget.value.length > 0 &&
                                                        setTaughtCourses(oldArray => [...oldArray, { id: oldArray.length, array: e.currentTarget.value }])
                                                }}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Space>

                                <br /><br />
                                <div>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            shape={"round"}
                                            type={"primary"}>Update</Button>
                                    </Form.Item>


                                    <Button onClick={() => { setEditToggleInfo(false) }}
                                        shape={"round"}
                                        type={"link"}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <div>
                            <Button
                                onClick={() => { setEditToggleInfo(true) }}
                                icon={<EditOutlined />}
                                shape={"round"}
                                disabled={teacherInfo.status.id === 2}
                            >Edit</Button>

                            <Space style={{ width: "100%", marginTop: 20, justifyContent: "space-between" }} direction={"horizontal"} align={"start"} wrap>
                                <div>
                                    <Paragraph strong> First name </Paragraph>
                                    <p>{teacherInfo.nameKz ?? "No name"}</p>

                                    <Paragraph strong> Last name </Paragraph>
                                    <p>{teacherInfo.surnameKz ?? "No surname"}</p>

                                    <Paragraph strong> Patronymic </Paragraph>
                                    <p>{teacherInfo.patronymicKz ?? "No patronymic"}</p>
                                </div>
                                <div>
                                    <Paragraph strong> First name (English) </Paragraph>
                                    <p>{teacherInfo.nameEn ?? "No name"}</p>

                                    <Paragraph strong> Last name (English) </Paragraph>
                                    <p>{teacherInfo.surnameEn ?? "No surname"}</p>
                                </div>
                                <div>
                                    <Paragraph strong> Position </Paragraph>
                                    <p>{teacherInfo.position?.name ?? "No position"}</p>

                                    <Paragraph strong> Type </Paragraph>
                                    <p>{teacherInfo.type?.name ?? "No Type"}</p>

                                    <Paragraph strong> Status </Paragraph>
                                    <p>{teacherInfo.status?.name ?? "No Status"}</p>

                                    <Paragraph strong> Department </Paragraph>
                                    <p>{teacherInfo.department?.titleEn ?? "No Department"}</p>
                                </div>
                                <div>
                                    <Paragraph strong> Scientific degree </Paragraph>
                                    <p>{teacherInfo.scientificDegree?.nameEn ?? "No degree"}</p>

                                    <Paragraph strong> Academic status </Paragraph>
                                    <p>{teacherInfo.academicStatus?.nameEn ?? "No status"}</p>

                                    <Paragraph strong> KPI position </Paragraph>
                                    <p>{teacherInfo.kpiPosition ?? "No Type"}</p>

                                    <Paragraph strong> Foreign Teacher </Paragraph>
                                    <p>{teacherInfo.ftutor ? "Yes" : "No"}</p>
                                </div>
                                <div>
                                    <Paragraph strong> IIN </Paragraph>
                                    <p>{teacherInfo.iin}</p>

                                    <Paragraph strong> Date of birthday </Paragraph>
                                    <p>{String(teacherInfo.birthDate)}</p>

                                    <Paragraph strong> Phone </Paragraph>
                                    <p>{teacherInfo.phone}</p>
                                </div>
                                <div>
                                    <Paragraph strong> Scientific interests </Paragraph>
                                    {scientificInterests ? scientificInterests.map((interest) => (
                                        <Tag key={interest.id}>{interest.array}</Tag>
                                    )) : (
                                        <p>No interests</p>
                                    )}
                                    <Paragraph strong style={{ marginTop: "20px" }}> Teaching disciplines </Paragraph>
                                    {taughtCourses ? taughtCourses.map((course) => (
                                        <Tag key={course.id}>{course.array}</Tag>
                                    )) : (
                                        <p>No courses</p>
                                    )}
                                </div>
                            </Space>
                            {/* <Space style={{ marginTop: 20, display: teacherInfo.ftutor && teacherAdditionalInfo ? "block" : "none" }}>
                                <Divider />
                                <Space direction="horizontal">
                                    {teacherAdditionalInfo?.map(info => (
                                        <div key={info.id}>
                                            <Paragraph strong>{typeTitles[info.type.name]}</Paragraph>
                                            <p>{info.value}</p>
                                        </div>
                                    ))}
                                </Space>
                            </Space> */}
                            <Space direction="horizontal" style={{ marginTop: 20 }}>
                                {teacherInfo.resumeId
                                    ?
                                    (<Space direction="horizontal">
                                        <Button icon={<DownloadOutlined />} onClick={handleDownloadResume}>Download resume</Button>
                                        <Upload {...props} showUploadList={false} multiple={false} onChange={handleUpload}>
                                            <Button icon={<UploadOutlined />}>Update resume</Button>
                                        </Upload>
                                    </Space>)
                                    :
                                    (<Upload {...props} multiple={false} showUploadList={false} onChange={handleUpload}>
                                        <Button icon={<UploadOutlined />} >Upload resume</Button>
                                    </Upload>)
                                }
                            </Space>
                        </div>
                    )
                }
            </Space >
        </Space>
    )
}
