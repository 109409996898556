import { Button, Form, Input, Modal, notification, Select, Space, Table, Tag, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { CertificateDtoRequest } from '../../../interfaces/deansOffice/CertificateDtoRequest';
import { CertificateIssuanceService } from '../../../services/deansOffice/CertificateIssuanceService';
import { CertificateIssuanceType } from '../../../interfaces/deansOffice/CertificateIssuanceType';
import { CertificateIssuanceRequestDtoResponse } from '../../../interfaces/deansOffice/CertificateIssuanceRequestDtoResponse';
import PageWrapper from '../../../ui/PageWrapper';
import { CertificateService } from '../../../services/deansOffice/CertificateService';
import { CertificateType } from '../../../interfaces/deansOffice/CertificateType';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 6 },
};

interface CertificateLocalDto {
  type: number;
  howToSend: string;
  city: string | null;
  district: string | null;
  reasonStudent: string | null;
}

const Certificate = () => {
  const [spinner, setSpinner] = useState(false);
  const [isMilitary, setIsMilitary] = useState(false);
  const [isReason, setIsReason] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rejectModalValue, setRejectModalValue] = useState<CertificateIssuanceRequestDtoResponse>();
  const [studentCertificateIssuance, setStudentCertificateIssuance] = useState<CertificateIssuanceRequestDtoResponse[]>(
    []
  );
  const [certificateTypes, setCertificateTypes] = useState<CertificateType[]>([]);
  const [form] = useForm();

  useEffect(() => {
    CertificateIssuanceService.getCertificateTypes()
      .then(({ data }) => setCertificateTypes(data.filter(c => c.id !== 1 && c.id !== 2)));
  }, []);

  const makeCertificate = (record: CertificateIssuanceRequestDtoResponse) => {
    setSpinner(true);
    CertificateService.makeCertificate(record)
      .then(() =>
        notification.success({
          message: 'Success',
          description: 'You successfully sent certificate issuance ' + record.issuanceType.toString(),
          duration: 1.5,
        })
      )
      .finally(() => setSpinner(false));
    fetchData();
  };

  const handleRejectModalShow = (record: CertificateIssuanceRequestDtoResponse) => {
    setRejectModalValue(record);
    setShowModal(true);
  };

  const columns = [
    {
      title: 'Full Name',
      render: (record: CertificateIssuanceRequestDtoResponse) =>
        concatValues(record.student.surnameEn, record.student.nameEn),
    },
    {
      title: 'Group',
      dataIndex: ['student', 'group', 'title'],
      key: 'group',
    },
    {
      title: 'Issuance type',
      dataIndex: 'issuanceType',
      key: 'issuanceType',
      filters: [
        {
          text: 'Email',
          value: CertificateIssuanceType.EMAIL,
        },
        {
          text: 'Pickup',
          value: CertificateIssuanceType.PICKUP,
        },
      ],
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issuanceType.indexOf(value) === 0,
    },
    {
      title: 'Type of certificate',
      dataIndex: ['type', 'name'],
      key: 'type',
      filters: certificateTypes.map((types) => {
        return {
          text: types.name,
          value: types.id,
        };
      }),
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.type.id === value,
    },
    {
      title: 'Requested date',
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      render: (record: CertificateIssuanceRequestDtoResponse) => moment(record.requestedDate).format('LL hh:mm'),
    },
    {
      title: 'Status',
      filters: [
        {
          text: 'Received',
          value: 'RECEIVED',
        },
        {
          text: 'Requested',
          value: 'REQUESTED',
        },
        {
          text: 'Denied',
          value: 'DENIED',
        },
      ],
      render: (record: CertificateIssuanceRequestDtoResponse) => {
        switch (record.issued) {
          case 'REQUESTED':
            return <Tag color="blue">Requested</Tag>;
          case 'RECEIVED':
            return <Tag color="green">Received</Tag>;
          case 'DENIED':
            return <Tag color="red">Denied</Tag>;
        }
      },
      onFilter: (value: any, record: CertificateIssuanceRequestDtoResponse) => record.issued.indexOf(value) === 0,
    },
    {
      title: 'Make certificate',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Button
          type="primary"
          onClick={() => makeCertificate(record)}
          disabled={record.issued === 'REQUESTED' || record.issued === 'DENIED'}
        >
          Download
        </Button>
      ),
    },
    {
      title: 'Reason',
      render: (record: CertificateIssuanceRequestDtoResponse) => (
        <Button
          disabled={record.issued !== 'DENIED'}
          onClick={() => handleRejectModalShow(record)}
        >
          Show reason
        </Button>
      ),
    },
  ];

  const concatValues = (val1: string | undefined, val2: string | undefined) => {
    if (val1 === void 0 || val2 === void 0) {
      return '';
    }
    return val1 + ' ' + val2;
  };

  const onFinish = (certificateLocalDto: CertificateLocalDto) => {
    setSpinner(true);
    if (certificateLocalDto.type !== 4) {
      certificateLocalDto.city = null;
      certificateLocalDto.district = null;
    }
    if (certificateLocalDto.type !== 9) {
      certificateLocalDto.reasonStudent = null;
    }

    const certificateDtoRequest: CertificateDtoRequest = {
      certificateTypeId: Number(certificateLocalDto.type),
      issuanceType:
        certificateLocalDto.howToSend === 'EMAIL' ? CertificateIssuanceType.EMAIL : CertificateIssuanceType.PICKUP,
      city: certificateLocalDto.city !== void 0 ? certificateLocalDto.city : null,
      district: certificateLocalDto.district !== void 0 ? certificateLocalDto.district : null,
      reasonStudent: certificateLocalDto.reasonStudent !== void 0 ? certificateLocalDto.reasonStudent : null,
    };

    CertificateIssuanceService.makeRequest(certificateDtoRequest)
      .then((data) => {
        notification.success({
          message: 'Success',
          description: 'Your data successfully requested!',
          duration: 1.5,
        });
        fetchData();
      })
      .finally(() => setSpinner(false));
  };

  const onSelect = (e: number) => {
    if (e === 4) {
      setIsMilitary(true);
    } else {
      if (e === 9) {
        setIsReason(true);
      } else {
        setIsReason(false);
      }
      setIsMilitary(false);
    }
  };

  const fetchData = () => {
    CertificateIssuanceService.getAllStudent().then((data) => {
      setStudentCertificateIssuance(data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeInputCity = (e: any) => {
    let value = e.target.value;

    value = value.replace(/[^\\ .А-Яа-яәіңғүұқөһӘІҢҒҮҰҚӨҺ-]/gi, '');

    form.setFieldsValue({ city: value });
  };

  const onChangeInputDistrict = (e: any) => {
    let value = e.target.value;

    value = value.replace(/[^\\ .А-Яа-яәіңғүұқөһӘІҢҒҮҰҚӨҺ-]/gi, '');

    form.setFieldsValue({ district: value });
  };

  return (
    <PageWrapper>
      <header>
        <Title level={1}>Certificate service</Title>
      </header>
      <Form
        name="validate_other"
        {...formItemLayout}
        onFinish={onFinish}
        layout="vertical"
        form={form}
        style={{ marginTop: '20px' }}
      >
        <Form.Item
          name="type"
          label="Type of certificate"
          hasFeedback
          rules={[{ required: true, message: 'Please select your certificate!' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Please select a certificate" onSelect={onSelect}>
            {certificateTypes.map((types) => (
              <Option value={types.id}>{types.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="howToSend"
          label="How to send?"
          hasFeedback
          rules={[{ required: true, message: 'Please select your option!' }]}
          style={{ marginBottom: '10px' }}
        >
          <Select placeholder="Please select an option">
            <Option value="EMAIL">Email</Option>
            {/*<Option value="PICKUP">Pickup</Option>*/}
          </Select>
        </Form.Item>
        <Form.Item
          label="Reason"
          name="reasonStudent"
          hidden={!isReason}
          rules={[{ required: isReason, message: 'Please input your reason!' }]}
          style={{ marginBottom: '10px' }}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label="City (only in Cyrillic)"
          name="city"
          hidden={!isMilitary}
          rules={[{ required: isMilitary, message: 'Please input your city!' }]}
          style={{ marginBottom: '10px' }}
        >
          <Input type="text" onChange={(e) => onChangeInputCity(e)} />
        </Form.Item>
        <Form.Item
          label="District (only in Cyrillic)"
          name="district"
          rules={[{ required: isMilitary, message: 'Please input your district!' }]}
          hidden={!isMilitary}
        >
          <Input onChange={(e) => onChangeInputDistrict(e)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={spinner}>
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Title level={5}>Students reason</Typography.Title>
          <TextArea readOnly value={rejectModalValue?.reasonStudent} />
          {rejectModalValue?.issued === 'DENIED' && rejectModalValue?.reasonDeans && (
            <>
              <Typography.Title level={5}>Deans reason</Typography.Title>
              <TextArea readOnly value={rejectModalValue?.reasonDeans} />
            </>
          )}
        </Space>
      </Modal>
      <Table dataSource={studentCertificateIssuance} columns={columns} bordered={true} scroll={{ x: '1200px' }} />
    </PageWrapper>
  );
};

export default Certificate;
