import { successNotification } from '../../../../helpers/successNotification';
import { StudentDtoResponse } from '../../../../interfaces/Student/StudentDtoResponse';
import { PsychologistService } from '../../../../services/PsychologistService';
import { useHistory } from 'react-router-dom';

interface TableColumns {
  id: number;
  userId: number;
  fullName: string;
  group?: string;
  courseGrade?: string;
}

const useStudentsTable = (data: StudentDtoResponse[], navigate: any, selectionStates: any) => {
  let history = useHistory();
  let filteredData: TableColumns[] = [];

  const { selectedStudentId, setSelectedStudentId, selectedStudent, setSelectedStudent } = selectionStates;
  // eslint-disable-next-line array-callback-return
  data.map((student) => {
    filteredData.push({
      id: student.id,
      userId: student.userId,
      fullName: student.fullName,
      group: student?.group?.title,
      courseGrade: student.courseGrade,
    });
  });

  const confirm = (studentId: any, date: any, time: any) => {
    PsychologistService.addAppointment(date + ' ' + time + ':00', studentId.userId)
      .then(() => {
        successNotification('You successfully made an appointment!')
        // fetchData();
        history.push('/appointment-list');
      });
  };
  const onRowSelect = (student: TableColumns) => {
    navigate.push(`/profile/${student.userId}`);
  };

  const handleSelect = (record: TableColumns, selected: any) => {
    if (selected) {
      setSelectedStudentId((ids: any) => [...ids, record.userId]);
    } else {
      setSelectedStudentId((ids: any) => {
        const index = ids.indexOf(record.userId);
        return [...ids.slice(0, index), ...ids.slice(index + 1)];
      });
    }
  };

  const selectRow = (record: TableColumns) => {
    const selectedRows = [...selectedStudentId];
    const selectedStudents = [...selectedStudent];
    const student = data.find((student) => student.userId === record.userId);
    if (selectedRows.indexOf(record.userId) >= 0) {
      selectedStudents.splice(selectedStudents.indexOf(student), 1);
      selectedRows.splice(selectedRows.indexOf(record.userId), 1);
    } else {
      selectedStudents.push({
        fullName: `${student?.fullName}`,
        group: student?.group?.title,
        id: student?.userId,
        userId: student?.userId,
      });
      selectedRows.push(record.userId);
    }
    setSelectedStudentId(selectedRows);
    setSelectedStudent(selectedStudents);
  };

  const rowSelection = {
    selectedRowKeys: selectedStudentId,
    onSelect: handleSelect,
  };

  return { filteredData, onRowSelect, selectRow, rowSelection, confirm };
};

export default useStudentsTable;
