import { Button, Form, InputRef, Space, Table, Tabs, Col, Row, Typography, Input, DatePicker, Select, } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import Page from '../../../../ui/Page/Page';
import useIros from '../useIros';
import moment from 'moment';
import { getColumnSearchProps } from '../../../../common/getColumnSearchProps';
import { useState } from 'react';
import ApplicationsTable from './ApplicationsTable';
import { Moment } from 'moment';


const { Option } = Select;
const { Title } = Typography;

const ApplicationsList = () => {
  const { data, handlers } = useIros();
  const [form] = useForm();
  const inputRef = React.useRef() as React.Ref<InputRef>;
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
  const [approved, setApproved] = useState<boolean | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<boolean>(true);

  const resColumns = [
    {
      title: '№',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      ...getColumnSearchProps('student', inputRef),
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
      filters: [
        {
          text: '3',
          value: 3,
        },
        {
          text: '2',
          value: 2,
        },
        {
          text: '1',
          value: 1,
        },
      ],
      onFilter: (value: any, record: any) => record.course === value,
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (_: any, record: any, idx: any) => moment(record.createdDate).format('LL'),
      sorter: (a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    },
    {
      title: 'AVG',
      dataIndex: 'avg',
      key: 'avg',
      sorter: (a: any, b: any) => a.avg - b.avg,
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      sorter: (a: any, b: any) => a.points - b.points,
    },
    {
      title: 'GPA',
      dataIndex: 'irosGpa',
      key: 'irosGpa',
      sorter: (a: any, b: any) => a.irosGpa - b.irosGpa,
    },
  ];

  const tabItems = [
    {
      label: 'All applications',
      key: 'item-1',
      children: (
        <>
          <ApplicationsTable
            approved={approved}
            applicationName={search}
            startDate={startDate}
            endDate={endDate}

          />

        </>
      ),
    },
    {
      label: 'Results',
      key: 'item-2',
      children: (
        <>

          <Space direction="vertical" style={{ width: '100%' }}>

            <Button type="primary" onClick={() => handlers.handleDownloadIrosResults()}>
              Get results
            </Button>

            <Table
              columns={resColumns}
              loading={data.loading}
              dataSource={data.irosResults.map((data, i) => ({
                key: i + 1,
                student: data.fullname,
                course: data.course,
                avg: data.avg,
                points: data.points,
                irosGpa: data.iros,
              }))}
            />
          </Space>
        </>
      ),
    },
  ];

  const onChange = (key: string) => {
    if (key == 'item-1')
      setFilter(true);
    else
      setFilter(false);

  };

  const handleClear = () => {
    setApproved(undefined);
  };

  const handleSearchByParam = (value: any) => {
    setSearch(value.name);
    setApproved(value.status);
    if (value.startDate) {
      setStartDate(value.startDate.format('YYYY-MM-DD'));
    } else {
      setStartDate(undefined);
    }
    if (value.endDate) {
      setEndDate(value.endDate.format('YYYY-MM-DD'));
    } else {
      setEndDate(undefined);
    }

  };

  return (
    <Page title="Applications List" subtitle="Applications of students to iROS">
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        {filter === true && (
          <Col span={14}>
            <Space direction="vertical">
              <Form form={form} onFinish={handleSearchByParam}>
                <Form.Item name="name" style={{ marginBottom: '20px' }}>
                  <Input placeholder="Поиск по Имени и Фамилии" />

                </Form.Item>
                <Form.Item name="status" style={{ marginBottom: '20px' }}>
                  <Select
                    placeholder="Выберите Статус"
                    allowClear
                    value={approved}
                    onClear={() => {
                      handleClear();
                    }}
                    style={{ width: 500 ? 500 : 600 }}>
                    <Option key={0} value={'false'}>
                      Not approved
                    </Option>
                    <Option key={1} value={true}>
                      Approved
                    </Option>
                  </Select>
                </Form.Item>
                <Space dir={'horizontal'} size="large" direction="horizontal">
                  <Space align="start">
                    <Title level={5}>От</Title>
                    <Form.Item name="startDate" style={{ marginBottom: '20px' }}>

                      <DatePicker placeholder="Выберите дату" />
                    </Form.Item>
                  </Space>
                  <Space align="start">
                    <Title level={5}>До</Title>
                    <Form.Item name="endDate" style={{ marginBottom: '20px' }}>

                      <DatePicker placeholder="Выберите дату" />
                    </Form.Item>

                  </Space>
                  <Space align="end" >
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ marginLeft: 20 }}>
                        Поиск
                      </Button>
                    </Form.Item>

                  </Space>
                </Space>

              </Form>
            </Space>
          </Col>
        )}
        <Col span={16}></Col>
      </Row>
      <Tabs defaultActiveKey="1" type="card" size={'large'} items={tabItems} onChange={onChange} />


    </Page>
  );
};

export default ApplicationsList;
